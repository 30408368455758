import {PageRouteBuilder} from "../../util/PageRouteBuilder";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import {Nav} from "react-bootstrap";


export const enum ButtonType {
    EXTERNAL = "external",
    PAGE = "page",
    BLOG = "blog",
    JOB = "job",
}

export type UniversalReadMoreButtonPayLoad = {
    type: ButtonType,
    value: string,
}


export const UniversalReadMoreButton = (props: { payload: { type: string, value: string, label: string }, pathName: string, siteId: string }) => {
    const location = useLocation();


    const ButtonProvider = () => {
        if (props.payload?.type === ButtonType.PAGE) {
            return <a className="theme-link" href={PageRouteBuilder.buildPageRoute(location.pathname ?? "", props?.payload?.value ?? "", props?.siteId ?? "")}>{props?.payload?.label}
            </a>
        } else if (props.payload?.type === ButtonType.EXTERNAL) {
            return <a className="theme-link" href={props?.payload?.value ?? ""}  target='_blank'>{props?.payload?.label}</a>
        } else if (props.payload?.type === ButtonType.BLOG) {
            return <Link className="theme-link" to={PageRouteBuilder.buildPageRoute(location.pathname ?? "", `blog/${props?.payload?.value}`, props?.siteId)}>
                {props?.payload?.label}
            </Link>
        } else if (props.payload?.type === ButtonType.JOB) {
            return <Link className="theme-link" to={PageRouteBuilder.buildPageRoute(location.pathname, `job/${props?.payload?.value}`, props?.siteId)}>
                {props?.payload?.label}
            </Link>
        }
    }

    return (<>
        {
            ButtonProvider()
        }
    </>)
}
