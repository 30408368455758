import axiosClient from "../config/AxiosRestClient";

export class ProductRepo {
    static getProducts(origin?: string, websiteId?: string, querySting?: string) {
        return axiosClient.get(`/listingfy-service/website/products`, {
            headers: origin?{
                origin: origin
            }: undefined,
            params: {
                'siteId': websiteId,
                'tags' : querySting
            }
        });
    }

    static getProductDetail(origin?: string, websiteID?: string, productId?: string) {
        return axiosClient.get(`/listingfy-service/website/products/${productId}`, {
            headers: {
                origin: origin
            },
            params: {
                'siteId': websiteID,
            }
        });
    }

}
