import {UniversalReadMoreButton} from "../../../../components/UniversalReadMoreButton/UniversalReadMoreButton";
import {MediaSelector} from "../MediaSelector/MediaSelector";
import React from "react";
import {LocateUsProps} from "../../../../models/BannerModel";
import ScrollAnimation from "react-animate-on-scroll";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";

export const LocateUs = (props: LocateUsProps) => {
    return (
        <section id={props.htmlId}
                 className={`${props.themeClass} ${props.shadeClass} testingFontFamily site_widget aboutUs-sec ptb_80`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 section-header">
                        <ScrollAnimation animateIn='fadeInLeft'>
                            <HeadingTitles heading={props.data['title']} classesObject={{headingClass:'heading-style', descriptionClass: 'sub-title fs-lg'}} headingTag="h2" layout="layout_1" description={props.data['subtitle']} alignment="start"/>
                        <p className="title_description mt-3">{props.data['address']}</p>
                        </ScrollAnimation>

                    </div>
                    <div className="col-lg-6 position-relative">
                        <ScrollAnimation animateIn='fadeInRight'>
                            <div dangerouslySetInnerHTML={{__html: props.data['googleMapEmbedLink'] || ''}}></div>
                        </ScrollAnimation>

                    </div>
                </div>
            </div>
        </section>
    );
}