import Testimonial from "./Testimonial";
import {TestimonialProps} from "../../../../models/BannerModel";
import "./Testimonial.scss";
import ScrollAnimation from "react-animate-on-scroll";
import AliceCarousel from "react-alice-carousel";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import React from "react";

export const TestimonialWidget = (props: TestimonialProps) => {

    return <section id={props?.htmlId} className={`${props.themeClass} testimonials position-relative`}>
        <div className="container py-14 py-md-16">
            <div className="triangle"></div>
            <div className="row gx-xl-12 gy-10 align-items-center">

                <div className="col-xl-4">
                    <ScrollAnimation animateIn='fadeInLeft'>
                    <HeadingTitles heading={props.data['title']} classesObject={{headingClass:'heading-style', descriptionClass: 'sub-title fs-lg'}} headingTag="h2" layout="layout_1" description={props.data['subtitle']} alignment="start"/>
                    </ScrollAnimation>
                </div>
                <div className="col-xl-8">
                    <ScrollAnimation animateIn='fadeInRight'>
                    <div className="position-relative d-flex align-items-center">
                        <div className="shape rounded-circle bg-soft-yellow rellax w-16 h-16" data-rellax-speed="1"></div>
                        <div className="shape rounded-circle bg-line-red rellax w-16 h-16" data-rellax-speed="1"></div>
                        <AliceCarousel items={props.data.testimonials?.map((testimonial: any) =>
                            <Testimonial id={testimonial.id} comment={testimonial?.comment} name={testimonial?.name}
                                         designation={testimonial?.designation} image={testimonial?.image}></Testimonial>
                        )}
                                       responsive={{
                                           0: {items: 1},
                                           568: {items: 1},
                                           1024: {items: 2},
                                       }}
                                       disableButtonsControls={true} disableDotsControls={false} autoPlayInterval={2000}
                                       animationDuration={1000} autoPlay={true} infinite={true} mouseTracking={true}
                        />
                    </div>
                    {/*<div className="padpro-flex justify-content-center mt-3">*/}
                    {/*    <button className="btn theme-btn effect-btn animate fadeInUp three">All Testimonials</button>*/}
                    {/*</div>*/}
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    </section>
}
