import React, {Component, RefObject} from "react";
import "./SubscribeNow.scss";
import {SubscribeNowProps} from "../../../../models/BannerModel";
import ScrollAnimation from "react-animate-on-scroll";

class SubscribeNow extends Component<SubscribeNowProps, any> {
    form: RefObject<HTMLFormElement>
    dropdown: RefObject<HTMLDivElement>

    constructor(props: any) {
        super(props);
        this.dropdown = React.createRef();
        this.state = {
            name: '',
            email: '',
        };
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        const target = event.target;
        const name = target.name;
        const value = this.extractEventValue(target);
        // @ts-ignore
        this.setState({
            [name]: value
        });
    }

    private extractEventValue(target: any) {
        if (target.type === 'checkbox') {
            return target.checked;
        } else if (target.type === 'file') {
            return target.files!![0];
        } else {
            return target.value;
        }
    }

    render() {
        return (
            <section id={this.props.htmlId}
                     className={`${this.props.themeClass} ${this.props.shadeClass} subscribe-sec testingFontFamily`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 section header">
                            <ScrollAnimation animateIn='fadeInLeft'>
                                <h2 className="heading-style">{this.props.data['title']}</h2>
                                <p className="mini-title">{this.props.data['subtitle']}
                                </p>
                            </ScrollAnimation>
                        </div>
                        <div className="col-md-6 text-center">
                            <ScrollAnimation animateIn='fadeInRight'>
                                <form action="#">
                                    <div className="subcribe-form mt-4" id="subscribe">
                                        <input className="form-control" name={"email"} id="email" type="email"
                                               placeholder="Your Email"
                                               onChange={event => this.handleChange(event)} required/>
                                        <button className="btn btn-default btn-dark_theme" type="submit">Submit</button>
                                    </div>
                                </form>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SubscribeNow;
