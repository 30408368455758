import {Component} from "react";
import "./ProductFeature.scss";
import {ProductFeatureRightProps} from "../../../../models/BannerModel";
import Carousel from "react-bootstrap/Carousel";
import LazyLoad from "react-lazyload";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import ScrollAnimation from "react-animate-on-scroll";
interface ProductFeatureState {
    name: string,
    description: string,
    imageSize: number,
    activeSlide: any,
}

class ProductFeature extends Component<ProductFeatureRightProps, ProductFeatureState> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            description: '',
            imageSize: this.props?.data?.imageSize ?? 0,
            activeSlide: '',
        };
    }

    setImageSize = () => {
        if (this.props?.data?.imageSize === 'XS') {
            return 25;
        } else if (this.props?.data?.imageSize === 'S') {
            return 50;
        } else if (this.props?.data?.imageSize === 'M') {
            return 75;
        } else if (this.props?.data?.imageSize === 'L') {
            return 100;
        }
    }

    render() {
        return (
            <section id={this.props.htmlId} className={`${this.props.themeClass} ${this.props.shadeClass} site_widget productFeature_l1 ptb_80 position-relative`}>
                <div className="container">
                    {this.props.config?.uiTemplate === 'product_feature_right' ?
                        <ScrollAnimation animateIn='fadeInUp'>
                        <div className="row">
                            <div className="col-md-6 section-header d-flex align-items-center">
                                <div className="float-start">
                                    <HeadingTitles heading={this.props.data['title']} classesObject={{headingClass:'heading-style', descriptionClass: 'mini-title'}} headingTag="h2"  description={this.props.data['subtitle']} alignment="start" layout="layout_2"/>
                                    <div dangerouslySetInnerHTML={{__html: this.props.data?.content?.html || ''}} className="title_description mt-3"></div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {
                                    this.props?.data?.slides?.length > 1 ?
                                        <Carousel indicators={false} controls={false}>
                                            {this.props.data.slides?.map((image: any, i: any) =>
                                                <Carousel.Item className="productFeature-img"
                                                               interval={this.props.data?.delay}>
                                                    <div className="d-flex justify-content-center h-100">
                                                        <LazyLoad offset={100}>
                                                        <img className="img-fluid d-block"
                                                             style={{width: `${this.setImageSize() ?? 75}%`}}
                                                             src={image.image.url} alt={i + 1} loading="lazy"/>
                                                        </LazyLoad>
                                                    </div>
                                                </Carousel.Item>
                                            )}
                                        </Carousel> :
                                        <div className="d-flex justify-content-center h-100 productFeature-img">
                                            <img className="img-fluid d-block"
                                                 style={{width: `${this.setImageSize() ?? 75}%`}} src={this.props?.data?.slides[0]?.image?.url}
                                                 alt={""} loading='lazy'/>
                                        </div>
                                }
                            </div>
                        </div>
                        </ScrollAnimation>
                        :
                        <ScrollAnimation animateIn='fadeInUp'>
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <Carousel indicators={false} controls={false}>
                                    {
                                        this.props?.data?.slides?.length > 1 ?
                                            <Carousel indicators={false} controls={false}>
                                                {this.props.data.slides?.map((image: any, i: any) =>
                                                    <Carousel.Item className="productFeature-img"
                                                                   interval={this.props.data?.delay}>
                                                        <div className="d-flex justify-content-center h-100">
                                                            <img className="img-fluid d-block"
                                                                 style={{width: `${this.setImageSize() ?? 75}%`}}
                                                                 src={image.image.url} alt={i + 1} loading="lazy"/>
                                                        </div>
                                                    </Carousel.Item>
                                                )}
                                            </Carousel> :
                                            <div className="d-flex justify-content-center h-100 productFeature-img">
                                                <img className="img-fluid d-block"
                                                     style={{width: `${this.setImageSize() ?? 75}%`}} src={this.props?.data?.slides[0]?.image?.url}
                                                     alt={""} loading='lazy'/>
                                            </div>
                                    }
                                </Carousel>
                            </div>
                            <div className="col-md-6 section-header d-flex align-items-center">
                                <div className="float-end">
                                    <div className="mb-4 ">
                                        <span className="label-line"></span>
                                        <span className="mini-title">{this.props.data['subtitle']}</span>
                                    </div>
                                    <h2 className="heading-style">{this.props.data['title']}</h2>
                                    <div dangerouslySetInnerHTML={{__html: this.props.data?.content?.html || ''}}
                                         className="title_description mt-3"></div>
                                </div>
                            </div>
                        </div>
                        </ScrollAnimation>
                    }
                </div>
            </section>
        );
    }
}

export default ProductFeature;
