import {ResponseBack} from "../../../util/AppTypes";

export function ShowPrevious (images: any, currentIndex: number, event: any, callback: ResponseBack) {
    event.stopPropagation();
    if (currentIndex <= 0) {
        const prevImage = images[images.length - 1].url;
        callback({
            imageSelected: prevImage,
            imageIndex: images.length - 1
        });
    } else {
        let prevImage = images[currentIndex - 1].url;
        callback({
            imageSelected: prevImage,
            imageIndex: currentIndex - 1
        });
    }
}
