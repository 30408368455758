import React, {Component} from "react";
import "./about_bsc.scss";
import {ComponentProps} from "../../../../util/CommonDto";

interface AboutMeProps extends ComponentProps<any, any> {
    content: string
}

class AboutMeBsc extends Component<AboutMeProps, any> {
    render() {
        return <div className="container">
            <div className="row">
                <div className="col-md-3">
                    <div className="about_block border bg-light rounded-3 p-2">
                        <div className="about_heading">
                            <h5>Profile Intro</h5>
                        </div>
                        <div className="about_content">
                            <ul className="personal_info">
                                <li>
                                    <h3 className="title">About Me</h3>
                                    <span className="text">Hi, I’m Marina, I’m 36 and I work as a primary teacher in Star Public School,Mohali,Punjab.</span>
                                </li>
                                <li>
                                    <h3 className="title">Qualification</h3>
                                    <span className="text">BSc & B.Ed</span>
                                </li>
                                <li>
                                    <h3 className="title">Experience</h3>
                                    <span className="text">less than 5 years</span>
                                </li>
                                <li>
                                    <h3 className="title">Location</h3>
                                    <span className="text">Sector 67 Market SAS Nagar, Mohali, Punjab 160062, India</span>
                                </li>
                                <li>
                                    <h3 className="title">Mobile Number</h3>
                                    <span className="text">+91 826454872</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12 mb-md-3 p-3 bg-light border description">
                            <h3 className="title">Description</h3>
                            <p className="text">Hi, I’m Marina, I’m 36 and I work as a primary teacher in Star Public School,Mohali,Punjab.
                                Hi, I’m Marina, I’m 36 and I work as a primary teacher in Star Public School,Mohali,Punjab.</p>
                        </div>
                        <div className="col-md-12 video_sec">
                            <iframe width="648" height="320" src="https://www.youtube.com/embed/ZBLL0pLRQ-U" title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                        <div className="row">
                            <div className="subjects_title mb-md-3 mt-md-3 bg-light border">
                                <h4 className="mb-md-3 mt-md-3">Subjects Taught</h4>
                        <div className="row mt-md-3">
                            <div className="col-md-4 mb-md-3">
                                <div className="subject_card_wrapper p-2 border">
                                    <div className="subject_card_content">
                                        <h5 className="bsc_service_card_title">
                                            English
                                        </h5>
                                        <p className="bsc_service_card_description mt-2">
                                            Mathematics tuition for Class 6-10, CBSE syllabus
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-md-3">
                                <div className="subject_card_wrapper p-2 border">
                                    <div className="subject_card_content">
                                        <h5 className="bsc_service_card_title">
                                            Hindi
                                        </h5>
                                        <p className="bsc_service_card_description mt-2">
                                            Mathematics tuition for Class 6-10, CBSE syllabus
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-md-3">
                                <div className="subject_card_wrapper p-2 border">
                                    <div className="subject_card_content">
                                        <h5 className="bsc_service_card_title">
                                            Social Studies
                                        </h5>
                                        <p className="bsc_service_card_description mt-2">
                                            Mathematics tuition for Class 6-10, CBSE syllabus
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-md-3">
                                <div className="subject_card_wrapper p-2 border">
                                    <div className="subject_card_content">
                                        <h5 className="bsc_service_card_title">
                                            Maths Tuition
                                        </h5>
                                        <p className="bsc_service_card_description mt-2">
                                            Mathematics tuition for Class 6-10, CBSE syllabus
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-md-3">
                                <div className="subject_card_wrapper border p-2">
                                    <div className="subject_card_content">
                                        <h5 className="bsc_service_card_title">
                                            Maths Tuition
                                        </h5>
                                        <p className="bsc_service_card_description mt-2">
                                            Mathematics tuition for Class 6-10, CBSE syllabus
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contact_form position-relative p-3 border bg-light rounded-3 p-2">
                        <h4 className="contact_title">Let Me Contact You</h4>
                        <label className="contact_sub_title ">Please leave your contact Details here.<br/>I will contact you real soon!</label>
                    <div className="contact-us-wrapper position-relative">
                        <div className="form-wrapper">
                            <form>
                            <div className="mb-3">
                                <input type="name" className="form-control" id="contact_us_name"
                                       placeholder="Your name"  required />
                            </div>
                            <div className="mb-3">
                                <input type="number" className="form-control" id="contact_us_mobile"
                                       placeholder="Phone"required/>
                            </div>
                            <div className="mb-3">
                                <input type="email" className="form-control" id="contact_us_mobile"
                                       placeholder="E-mail"required/>
                            </div>
                            <div className="mb-3">
                                <input type="message" className="form-control" id="contact_us_mobile"
                                       placeholder="Subject" />
                            </div>
                            <div className="mb-4">
                                <textarea className="form-control" id="exampleFormControlInput1"
                                          placeholder="Leave your message here" rows={3}  />
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn btn-submit">Submit</button>
                            </div>
                        </form>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>;
    }
}

export default AboutMeBsc;
