import React, {Component} from "react";
import {LinearPercentileIndicator} from "./LinearPercentileIndicator";
import {LinearPercentProps} from "../../../../../models/BannerModel";

class LinearPercentile extends Component<LinearPercentProps, any>{

    render() {
        return <div className="container justify-content-between d-flex text-center ptb_80">
            <div className="col-6" style={{backgroundImage: `url(https://wallpaperaccess.com/full/1463431.jpg)`, backgroundSize: "cover"}}>
            </div>
            <div className="col-6 ">{
                this.props?.data?.percentiles?.map((indicator: any) => {
                    return <LinearPercentileIndicator title={indicator?.title} duration={this.props?.data?.duration} percent={indicator?.value}/>
                })
            }</div>
        </div>;
    }
}

export default LinearPercentile;
