import axiosClient from "../config/AxiosRestClient";
import {Blog} from "../models/Blog";

export class BlogsRepo {
    static getBlogs(page : number, siteId?: string) {
        return axiosClient.get(`/listingfy-service/website/blogs`, {
            params: {
                siteId: siteId,
                page : page,
            },
        });
    }

    static getBlogsDetails = (blogId: string, siteId: string, origin?: string) => {
        return axiosClient.get<Blog>(`/listingfy-service/website/blogs/${blogId}`, {
            params: {
                siteId: siteId
            },
            headers: {
                origin: origin
            }
        })
    }
}
