import { Navbar } from "react-bootstrap";
import { useParams } from 'react-router-dom';
export const Navbrand = (props: any) => {
    let params = useParams<any>();
    return (
        (props.data['logoTitle'] && props.data['logo']['url']) ?
            <Navbar.Brand href={`/`} className="m-0 py-2 d-flex align-items-center">
                <img className="logo" id={props.data?.logo?.cid.substring(0,props.data?.logo?.cid.lastIndexOf('.')|| props.data?.logo?.cid)} src={props.data['logo']['url']} style={{
                    height:`${props.data['logoHeight'] ? props.data['logoHeight']:65}px`
                }} loading='lazy' alt={""}/>
                <h4 className="ms-2 logo_title" style={{fontWeight:'600'}}>{props.data['logoTitle']}</h4>
            </Navbar.Brand>
        : (props.data['logoTitle']) ?
            <h2 className="mt-2">{props.data['logoTitle']}</h2>
        : <Navbar.Brand href={`/`}>
                <img className="logo" id={props.data?.logo?.cid.substring(0,props.data?.logo?.cid.lastIndexOf('.')|| props.data?.logo?.cid)} src={props.data['logo']['url']} style={{
                    height:`${props.data['logoHeight'] ? props.data['logoHeight']:65}px`
            }} loading='lazy' alt={""}/>
            </Navbar.Brand>
    );
}
