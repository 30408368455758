import React, {Component} from "react";
import {CallUsProps} from "../../../../models/BannerModel";
import './CallUs.scss'
import ScrollAnimation from "react-animate-on-scroll";


class CallUs extends Component<CallUsProps, any> {

    constructor(props: any) {
        super(props);

        this.setItems();
        this.state = {
            name: '',
            description: '',
        };
    }

    sectionStyle: any = {
        backgroundImage: `url(${this.props?.data?.bgImage?.url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    }
    setItems = () => {
    }

    render() {
        return <section id={this.props.htmlId} style={this.props?.data?.bgImage?.url ? this.sectionStyle : {}}
                        className={`${this.props.themeClass} call_us`}>
            <div className="callus__overlay" style={{backgroundColor: `rgba(0,0,0,${(this.props.data?.bgOpacity / 10) ?? 0.7})`}}></div>
                <div className="container">
                    <div className="row">
                        <div className="content-wrapper">
                            <ScrollAnimation animateIn='flipInX'><h1 className="heading-style mb-3">{this.props.data['title']}</h1></ScrollAnimation>
                            <h2 className="sub-title mb-5">{this.props.data['subtitle']}</h2>
                            <ScrollAnimation animateIn='rotateInDownLeft'><p className="mini-title">{this.props.data['description']}</p></ScrollAnimation>
                      <div className="contactUs_wrap">
                          <a href={`tel:${this.props.data['contact']}`} className="contactUs-items">
                              <i className="bi bi-telephone me-2"></i>
                              <span className="text-white">{this.props.data['contact']}</span>
                          </a>
                          {this.props.data['whatsapp'] ?
                              <a  href={`https://wa.me/91${this.props.data['whatsapp']}?text=Hi, I have an enquiry for you`} target={"_blank"}
                                  className="contactUs-items">
                                  <i className="bi bi-whatsapp me-2"></i>
                                  <span className="text-white">{this.props.data['whatsapp']}</span>
                              </a> : ''}
                      </div>
                        </div>
                    </div>
            </div>


        </section>;
    }

}

export default CallUs;
