import {ResponseBack} from "../../../util/AppTypes";

export function ShowNext (images: any, currentIndex: number, event: any, callback: ResponseBack) {
    event.stopPropagation();
    if (currentIndex >= images.length - 1) {
        const nextImage = images[0].url;
        callback({
            imageSelected: nextImage,
            imageIndex: 0
        });
    } else {
        const nextImage = images[currentIndex + 1].url;
        callback({
            imageSelected: nextImage,
            imageIndex: currentIndex + 1
        });
    }
}
