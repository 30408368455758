import React, {useEffect, useState} from "react";
import {BlogsProps} from "../../../../models/BannerModel";
import {BlogsRepo} from "../../../../repo/BlogsRepo";
import {useParams} from "react-router";
import {BlogLayout} from "./BlogLayout";
import {Button} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";

interface BlogState {
    id : string,
    title: string,
    description: string,
    content: string,
    image : {cid : string, url : string},
    createdDate : number,
    active : boolean,
}

export const BlogWidget = ( props : BlogsProps) => {
    const [blogs, setBlogs] = useState<BlogState[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const params = useParams<any>();

    useEffect(()=> {
        getBlogs(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBlogs = (page : number) => {
        BlogsRepo.getBlogs(page, params?.id).then((res)=>{
            setBlogs(page === 0 ? res.data.content : blogs.concat(res.data?.content));
            setCurrentPage(page + 1);
            setTotalPages(res.data.totalPages);
        }).catch((err)=>{
            console.log("#35 Blog Widget:",err);
        })
    }

    return (
        <section  id={props.htmlId} className={`${props.themeClass}  ptb_80 position-relative testingFontFamily`} >
            <div className="container">
                <div className="section-heading text-center mb-5">
                    <ScrollAnimation animateIn='fadeInDown'>
                        <HeadingTitles heading={props.data?.title ?? ''} classesObject={{headingClass:'heading-style mb-0', descriptionClass: 'sub-title text-muted'}} layout="layout_1" headingTag="h2" description={props.data?.subtitle ?? ''} alignment="left"/>
                    </ScrollAnimation>

                </div>
                <ScrollAnimation animateIn='fadeInUp'>

                {currentPage < totalPages ?
                            <Button className="btn btn-submit" type="submit" style={{height : '50px', width: "150px", color: "white"}} onClick={(e)=> {
                                e.preventDefault();
                                getBlogs(currentPage);
                            }}>Load More</Button> : ''}
                <div className="row">
                    {blogs?.map((blog: any) =>
                        <BlogLayout id={blog?.id ?? ''} title={blog?.title ?? ''} description={blog?.description ?? ''} image={blog?.image} createdDate={blog?.createdDate} slug={blog?.slug}></BlogLayout>
                    )}
                </div>
                </ScrollAnimation>
            </div>

        </section>
    );
}
