import React, {Component} from "react";
import "./GalleryLayout1.scss";
import ScrollAnimation from "react-animate-on-scroll";
import {GalleryProps} from "../../../../models/GalleryModal";
import AliceCarousel from "react-alice-carousel";
import 'react-alice-carousel/lib/alice-carousel.css';
import {ShowNext} from "../../../../components/commonFunctions/gallery/ShowNext";
import {ShowPrevious} from "../../../../components/commonFunctions/gallery/ShowPrevious";
import {PreviousNext} from "../../../../components/commonFunctions/gallery/buttonIcons/PreviousNext";
import Close from "../../../../components/commonFunctions/gallery/buttonIcons/Close";

class GalleryLayout1 extends Component<GalleryProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            description: '',
            thumbsSwiper: '',
            imageSelected: '',
            showLightBox: false,
            imageIndex: 0,
            isAutoplay: false,
            delay: 0,
            images: [],
            responsive: {}
        };
    }

    componentDidMount() {
        this.setState({
            ...this.state, isAutoplay: this.props.data?.autoPlay, delay: this.props.data?.delay ?? 1000,
            images: this.props.data?.photos.map((photo: any, index: number) =>
                <div className="col product-img-container img-bg cursor-pointer">
                    <img className="image product-img-full" src={photo.url} onClick={(e) => {
                        this.setState({...this.state, imageSelected: photo.url, showLightBox: true})
                    }} loading='lazy' alt={''}/>
                </div>
            ),
            responsive:
                {
                    0: {items: 1},
                    568: {items: 3},
                    1024: {items: 5},
                }
        })
    }

    render() {
        return (
            <section id={this.props.htmlId}
                     className={`${this.props.themeClass} gallery-sec_l1 pb-5 pt-5 d-flex flex-column justify-content-center`}>
                <div className="container">
                    <ScrollAnimation animateIn='fadeInUp'>
                    <div className="row justify-content-center">
                        <div className="section-heading text-center mb-5">
                            <h2>{this.props.data['title']}</h2>
                            <span className="label-line"></span>
                            <p>{this.props.data['subtitle']}</p>
                            <p>{this.props.data['description']}</p>
                        </div>
                    </div>
                    </ScrollAnimation>
                </div>
                <div className="gallery-slider">
                    <AliceCarousel items={this.state?.images} autoPlay={this.state.isAutoplay} infinite={true}
                                   responsive={this.state.responsive} paddingLeft={5} paddingRight={5}
                                   disableButtonsControls={true} disableDotsControls={false}
                                   autoPlayInterval={this.state.delay}
                                   animationDuration={1000}/>
                </div>
                {
                    this.state.showLightBox ?
                        <div id="lightbox" onClick={()=> this.setState({...this.state, showLightBox: false})}>
                            <div style={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                            }} className="mt-4">
                                <Close onClick={() => this.setState({...this.state, showLightBox: false})}/>
                            </div>
                            {PreviousNext(true, (e)=> {
                                ShowPrevious(this.props.data?.photos, this.state.imageIndex, e, (res)=> {
                                    this.setState({
                                        ...this.state,
                                        imageSelected: res?.imageSelected,
                                        imageIndex: res?.imageIndex,
                                    })
                                })
                            })}
                            <img id="lightbox-img" src={this.state.imageSelected} loading='lazy' alt={""}/>
                            {PreviousNext(false, (e)=> {
                                ShowNext(this.props.data?.photos, this.state.imageIndex, e, (res)=> {
                                    this.setState({
                                        ...this.state,
                                        imageSelected: res?.imageSelected,
                                        imageIndex: res?.imageIndex,
                                    })
                                })
                            })}
                        </div>
                        : ''
                }
            </section>
        );
    }
}

export default GalleryLayout1;













