import {Callback} from "../../../../util/AppTypes";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const Close = (props: { onClick: Callback }) => {
    return <FontAwesomeIcon type="button" icon={faTimes as IconProp} className="icons mx-5"
                            style={{color: "white",  userSelect: "none",
                                background: '#000',
                                height: 40,
                                width: 40,
                                borderRadius: 50,
                                padding: 10}}
                            onClick={props.onClick}/>
}
export default Close;
