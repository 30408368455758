import React, {Component, RefObject} from "react";
import "./ComingSoon.scss"
import SocialIcon from "../../../../components/SocialIcon";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons/faFacebookF";
import {faInstagramSquare, faLinkedinIn, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {ComingSoonProps} from "../../../../models/BannerModel";

class ComingSoon extends Component<ComingSoonProps,any> {
    form: RefObject<HTMLFormElement>
    dropdown: RefObject<HTMLDivElement>

    constructor(props: any) {
        super(props);
        this.dropdown = React.createRef();
        this.state = {
            name: '',
            description: '',
        };
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        const target = event.target;
        const name = target.name;
        const value = this.extractEventValue(target);
        // @ts-ignore
        this.setState({
            [name]: value
        });
    }

    private extractEventValue = (target: any) => {
        if (target.type === 'checkbox') {
            return target.checked;
        } else if (target.type === 'file') {
            return target.files!![0];
        } else {
            return target.value;
        }
    }

    componentDidMount() {
    }

    parseDate(dateAsString: string): Date {
        return new Date(dateAsString);
    }

    render() {
        return (
            <section id={this.props.htmlId}   className={`${this.props.themeClass} comingSoon pb-5 pt-5 d-flex flex-column justify-content-center`} >
                <div className="container">
                    <div className="row">
                        {this.props.data['logo']['url'] ?
                            <div className="bg-img d-flex justify-content-center" style={{
                                height: 200
                            }}>
                                <img className="logo" src={this.props.data['logo']['url']} loading="lazy" alt={""}/>
                            </div>
                            : null}

                        <div className="heading-content text-center mb-5">

                            <h1>{this.props.data['title']}</h1>
                            <h2>{this.props.data['subtitle']}</h2>
                            <p>{this.props.data['description']}
                            </p>
                            <span>{this.parseDate(this.props.data['date']).toISOString().slice(0,10)}</span>
                        </div>

                        <div className="d-flex justify-content-center">
                            <div className="social-icon">
                                <SocialIcon url={this.props.data['facebookProfile']} icon={faFacebookF}/>
                                <SocialIcon url={this.props.data['twitterProfile']} icon={faTwitter}/>
                                <SocialIcon url={this.props.data['youtubeProfile']} icon={faYoutube}/>
                                <SocialIcon url={this.props.data['linkedInProfile']} icon={faLinkedinIn}/>
                                <SocialIcon url={this.props.data['instagramProfile']} icon={faInstagramSquare}/>
                            </div>
                        </div>
                    </div>
                    {this.props.data['showSubscribe'] ?
                        <div className="col-md-4 text-center">
                            <form action="#">
                                <div className="subcribe-form mt-4">
                                    <input className="form-control" name={"email"} id="email" type="email"
                                           placeholder="Your Email"
                                           onChange={event => this.handleChange(event)} required/>
                                    <button className="btn btn-md btn-primary" type="submit">Subscribe</button>
                                </div>
                            </form>
                        </div> : null}
                </div>
            </section>
        );
    }
}

export default ComingSoon;
