import {ResponseBack} from "../../../../util/AppTypes";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

export const PreviousNext = (isLeft: boolean, onClick: ResponseBack) => {
    return <FontAwesomeIcon type="button" icon={isLeft ? faChevronLeft as IconProp : faChevronRight as IconProp}
                            style={{
                                color: "white",
                                fontSize: "35px",
                                userSelect: "none",
                                background: '#000',
                                height: 60,
                                width: 60,
                                borderRadius: 50,
                                padding: 15
                            }}
                            className="buttonStyle icons mx-5" onClick={(e: any) => onClick(e)}/>
}
