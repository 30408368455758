import React, {Component} from "react";
import {WhatsappProps} from "../../../../models/BannerModel";
import "./Whatsapp.scss";
import wtsp_img from '../../../../assets/css/images/whatsAppIcon.png';
import "bootstrap-icons/font/bootstrap-icons.css";

class Whatsapp extends Component<WhatsappProps, any> {

    render() {
        return <div className="position-relative">
            {this.props?.data?.phoneNumber ?
                <a href={`https://wa.me/91${this.props?.data?.phoneNumber}?text=${this.props?.data?.queryMessage ?? ''}`}
                   className="wtsp_floatingBtn animate__animated animate__pulse animate__infinite" target={"_blank"}>
                   <img src={wtsp_img}/>
                    <span className="whatsappChats_Text">Chat with us on <strong>WhatsApp</strong></span>
                </a> : ''}
        </div>;
    }
}

export default Whatsapp;
