import React, {useEffect, useState} from "react";

export const Counter = (props: {title: string, target: number, suffix?: string}) => {
    const [count, setCount] = useState<number>(0);

    useEffect(()=>{
        if(count < props.target){
            setTimeout(()=>{
                setCount((count + (props?.target/500)));
            }, 10);
        }else{
            setCount(props.target);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count]);

    return <div className="col-md-3 text-center">
        <h2 className="heading-style fw-bold" style={{letterSpacing: '8px'}}>{count === props.target ? count : count.toFixed(0)}{props?.suffix}</h2>
        <p className="sub-title">{props?.title ?? ''}</p>
    </div>
}
