import React, { Component, FormEvent, RefObject } from "react";
import { PledgeFormProps } from "../../../../models/BannerModel";
import axiosClient from "../../../../config/AxiosRestClient";
import toast from "react-hot-toast";
import LoaderButton from "../../../../components/LoaderButton";
import pledgeFormImg from "../../../../assets/css/images/WhatsApp Image 2024-11-08 at 17.49.20.jpeg";
import './PledgeForm.scss';
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import ScrollAnimation from "react-animate-on-scroll";

interface PledgeFormBscState {
    processing: boolean;
    messageSent: boolean;
    formId: number | null;
    name: string;
    gender: string;
    pincode: string;
    state: string;
    district: string;
    email: string;
    mobile: string;
    dob: string;
}

class PledgeForm extends Component<PledgeFormProps, PledgeFormBscState> {
    form: RefObject<HTMLFormElement>;

    constructor(props: PledgeFormProps) {
        super(props);
        this.state = {
            processing: false,
            messageSent: false,
            formId: null,
            name: '',
            gender: '',
            district: '',
            state: '',
            pincode: '',
            email: '',
            dob: '',
            mobile: '',
        };
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const { name, value } = event.target;
        this.setState({ [name]: value } as unknown as PledgeFormBscState);
    }

    getPayload = (): any => {
        const { email, name, gender, mobile, district, state, pincode, dob } = this.state;
        return { email, name, gender, mobile, district, state, pincode, dob };
    };

    submitPledgeForm = (event: FormEvent) => {
        event.preventDefault();
        this.setState({ processing: true });

        axiosClient.post(`/listingfy-service/pledge`, this.getPayload())
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Form submitted successfully');
                    this.setState({
                        processing: false,
                        messageSent: true,
                        formId: res.data.id // Assuming `res.data.id` contains the form ID
                    });
                } else {
                    toast.error("Unknown error submitting form");
                    this.setState({ processing: false });
                }
            })
            .catch((error) => {
                console.error("Pledge Form Submission Error:", error);
                toast.error('Form already submitted by user');
                this.setState({ processing: false });
            });
    };

    downloadPledgeForm = () => {
        const { formId } = this.state;
        if (!formId) {
            console.error("Form ID is required to download the pledge form");
            return;
        }

        axiosClient.get(`listingfy-service/pledge-form/${formId}/download`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'PledgeForm.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error downloading pledge form:', error);
                toast.error('Error downloading pledge form');
            });
    };


    render() {
        return (
            <section id={this.props.htmlId} className={`${this.props.themeClass} pledgeForm ptb_80 testingFontFamily`}>
                <div className="container">
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <div className={''}>
                                <div className={'d-flex justify-content-center'}>
                                    <img src={pledgeFormImg} className={'w-75 rounded'} />
                                </div>
                                <p className={'text-white mt-3'} style={{ textAlign: 'justify' }}>
                                    At Pushpa Seva Foundation, we are passionate about making a difference in the
                                    lives of those affected by cancer. We strive to empower individuals with the
                                    knowledge and resources they need to make informed decisions about their health
                                    and well-being. With our dedicated team of volunteers and partners, we are
                                    committed to making a positive impact in the fight against cancer. We also provide
                                    support and counseling services to those affected by cancer, including patients, survivors, and
                                    their families.
                                </p>
                            </div>
                        </div>
                        <div className={'col-md-6'}>
                            <div className={'bg-white rounded pt-3 pb-4 px-4'}>
                                <div className="row justify-content-center mb-3">
                                    <ScrollAnimation animateIn='fadeInUp'>
                                        <HeadingTitles heading={this.props.data['title']} classesObject={{
                                            headingClass: 'heading-style mb-0',
                                            descriptionClass: 'sub-title text-muted'
                                        }} layout="layout_1" headingTag="h2" alignment="center" />
                                    </ScrollAnimation>
                                </div>
                                <div className="pledge-form text-center">
                                    <form id="contact-form" role="form" onSubmit={this.submitPledgeForm}>
                                        <div className="row">
                                            <div className="user__details">
                                                <div className="input__box">
                                                    <span className="details">Name</span>
                                                    <input className={'input'} type="text" placeholder="E.g: John Smith"
                                                           name={"name"} onChange={event => this.handleChange(event)}/>
                                                </div>
                                                <div className="input__box">
                                                    <span className="details">Email</span>
                                                    <input className={'input'} type="email"
                                                           placeholder="johnsmith@hotmail.com"
                                                           name={"email"} onChange={event => this.handleChange(event)}/>
                                                </div>
                                                <div className="input__box">
                                                    <span className="details">Date of Birth</span>
                                                    <input className={'input'} type="date" placeholder="02/06/2011"
                                                           name={"dob"} onChange={event => this.handleChange(event)}/>
                                                </div>
                                                <div className="input__box">
                                                    <span className="details">Gender</span>
                                                    <div className={'d-flex'}>
                                                        <div className={'d-flex mt-3 mb-3'}>
                                                            <input type="radio" id={'gender'} value='MALE' name="gender"
                                                                   className={'me-4'}
                                                                   onChange={event => this.handleChange(event)}/>
                                                            <label id={'gender'}>Male</label>
                                                        </div>
                                                        <div className={'d-flex ms-4 mt-3 mb-3'}>
                                                            <input type="radio" id={'gender'} value='FEMALE' name="gender"
                                                                   className={'me-4'}
                                                                   onChange={event => this.handleChange(event)}/>
                                                            <label id={'gender'}>Female</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="input__box">
                                                    <span className="details">Phone Number</span>
                                                    <input type="tel" className={'input'} placeholder="98*** ***18"
                                                           name={"mobile"}
                                                           onChange={event => this.handleChange(event)}/>
                                                </div>
                                                <div className="input__box">
                                                    <span className="details">State</span>
                                                    <input type="text" placeholder="E.g: U.P" name={"state"}
                                                           className={'input'}
                                                           onChange={event => this.handleChange(event)}/>
                                                </div>
                                                <div className="input__box">
                                                    <span className="details">District</span>
                                                    <input type="text" placeholder="E.g: U.P" name={"district"}
                                                           className={'input'}
                                                           onChange={event => this.handleChange(event)}/>
                                                </div>
                                                <div className="input__box">
                                                    <span className="details">Pincode</span>
                                                    <input type="text" placeholder="E.g: 282010" name={"pincode"}
                                                           className={'input'}
                                                           onChange={event => this.handleChange(event)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <LoaderButton loading={this.state.processing} label={'Submit Form'}
                                                      cssName={"btn button_theme mb-3"}/>
                                    </form>

                                    {this.state.messageSent && (
                                        <button className={'btn btn-sm btn-danger'} onClick={this.downloadPledgeForm}>
                                            <i className="bi bi-download"></i> Download Pledge Form
                                        </button>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default PledgeForm;
