import {Helmet} from "react-helmet";
import React, {FormEvent, useState} from "react";
import {Job} from "../../../../models/Job";
import {fileUploadRepo} from "../../../../repo/FileUploadRepo";
import axiosClient from "../../../../config/AxiosRestClient";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShareAlt as share, faMapMarkerAlt as loc} from '@fortawesome/free-solid-svg-icons';
import "./JobWidget.scss";

export const JobWidget = (props: { config: Job, htmlId?: string, themeClass?: string }) => {
    const [selectedImage, setSelectedImage] = useState<any>({
        cid: null,
        url: null
    });
    const [submitCheck, setSubmitCheck] = useState<boolean>(false);
    const [state, setState] = useState({
        processing: false,
        messageSent: false,
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        experience: '',
        noticePeriod: '',
        education: '',
        coverLetter: '',
    });
    const fileInputHandler = (event: any) => {
        let file = event.target.files!![0];
        uploadMedia(file);
    }
    const uploadMedia = (file: File) => {
        if (file) {
            fileUploadRepo.uploadFile(file).then(result => {
                setSelectedImage({
                    cid: result.cid,
                    url: result.url,
                });
            }).catch(err => {
                console.log("#41 Job Widget:", err);
            });
        } else {
        }

    }

    const handleChange = (event: any) => {
        const target = event.target;
        const name = target.name;
        const value = extractEventValue(target);
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const extractEventValue = (target: any) => {
        if (target.type === 'checkbox') {
            return target.checked;
        } else if (target.type === 'file') {
            return target.files!![0];
        } else {
            return target.value;
        }
    }
    const submitJob = (event: FormEvent) => {
        event.preventDefault();
        submitJobToServer();
    }
    const submitJobToServer = () => {
        axiosClient.post(`/listingfy-service/admin/job/${props.config.id}/response`, getPayload()).then((res) => {
            setSubmitCheck(true);
        }).catch((err) => {
            console.log("#75 Job Widget:", err);
            setSubmitCheck(false);
        });
    }

    const getPayload = (): any => {
        return {
            "firstName": state.firstName,
            "lastName": state.lastName,
            "cid": selectedImage.cid,
            "email": state.email,
            "mobile": state.mobile,
            "experience": state.experience,
            "noticePeriod": state.noticePeriod,
            "education": state.education,
            "coverLetter": state.coverLetter,
        };
    }

    const copyLink = () => {
        // navigator.clipboard.writeText(`https://carvia.listingfy.in/job/${slug}`);
        // AllToasts.success("Link copied");
    }


    return <section id={props.htmlId} className={`${props.themeClass} testingFontFamily`}>
        <div className="container">
            <Helmet>
                <title>{props.config?.title}</title>
            </Helmet>
            <div className="row">
                <div className="col-md-7">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <h1 className="heading-style">{props.config?.title ?? ""}</h1>
                        {/*<a className="icons me-2">*/}
                            {/*<FontAwesomeIcon icon={share} onClick={copyLink}/>*/}
                        {/*</a>*/}
                    </div>
                    <div className="job_location mb-2 sub-title">
                        <p><FontAwesomeIcon icon={loc} className="me-2"/>{props.config?.location}</p>
                    </div>
                    <div className="col-md-8">
                        <table className="table table-bordered mb-3">
                            <thead>
                            <tr>
                                <th>
                                    <h4 className="info-tittle">Job Type</h4>
                                    {props.config?.jobType != null ?
                                        <span className="info-content">{props.config?.jobType}</span> :
                                        <span className="info-content">NA</span>}
                                </th>
                                <th>
                                    <h4 className="info-tittle">Experience</h4>
                                    {props.config?.experience != null ?
                                        <span
                                            className="info-content">{props.config?.experience?.min} to {props.config?.experience?.max} Yrs.</span> :
                                        <span className="info-content">NA</span>}

                                </th>
                                <th>
                                    <h4 className="info-tittle">Offer Salary</h4>
                                    {props.config?.salary != null ?
                                        <span
                                            className="info-content">{props?.config?.salary?.min} to {props?.config?.salary?.max} LPA</span> :
                                        <span className="info-content">NA</span>}

                                </th>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <h2 className="mb-3 ">Skills</h2>
                    {props.config?.skill?.length !== 0 ? <div>
                        <p>{props.config?.skill.split(',').map((res: any) => {
                            return <span className="skill_job">{res}</span>;
                        })
                        }</p>
                    </div> : <div>
                    </div>}
                    <h2>Description:</h2>
                    <p>{props.config?.description}</p>
                    <div dangerouslySetInnerHTML={{__html: props.config?.content}}/>
                </div>
                <div className="col-md-5">
                    {submitCheck ? <div id="contact-form-box" className={"alert alert-success"}>Job Application Submitted
                            successfully</div> :
                        <div className="contact-box">
                            <div className="form-container">
                                <form onSubmit={submitJob} role="form">
                                    <h4 className="mb-4">Application</h4>
                                    <div className="row">
                                        <div className="col-6 mb-3">
                                            <label className="form-label labels">First name <span className="text-danger"> *</span></label>
                                            <input type="text" className="form-control form-input" id="inputPassword2"
                                                   name={"firstName"}
                                                   placeholder="First Name" onChange={(event) => {
                                                handleChange(event)
                                            }} required/>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <label className="form-label labels">Last name</label>
                                            <input type="text" className="form-control form-input"
                                                   placeholder="Last Name" name={"lastName"} onChange={(event) => {
                                                handleChange(event)
                                            }}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label labels">Email-id</label>
                                            <input type="email" className="form-control form-input" id="exampleFormControlInput1"
                                                   name={"email"}
                                                   placeholder="name@example.com" onChange={(event) => {
                                                handleChange(event)
                                            }}/>
                                        </div>
                                        <div className="input-group mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label labels">Mobile number <span
                                                className="text-danger">*</span></label>
                                            <span className="form-input me-2" id="basic-addon1">+91</span>
                                            <input type="text" className="form-control form-input" name={"mobile"}
                                                   placeholder="Mobile Number" maxLength={10} pattern={"[0-9]*"}
                                                   aria-label="Username" aria-describedby="basic-addon1"
                                                   onChange={(event) => {
                                                       handleChange(event)
                                                   }} required/>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <label className="form-label labels">Experience</label>
                                            <input type="text" className="form-control form-input"
                                                   placeholder="Experience" name={"experience"} onChange={(event) => {
                                                handleChange(event)
                                            }}/>
                                        </div>
                                        <div className="col-6 mb-3">
                                            <label htmlFor="inputPassword2" className="form-label labels">Notice period</label>
                                            <input type="text" className="form-control form-input" id="inputPassword2"
                                                   placeholder="NoticePeriod" name={"noticePeriod"} onChange={(event) => {
                                                handleChange(event)
                                            }}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label labels">Qualification</label>
                                            <input type="text" className="form-control form-input" id="exampleFormControlInput1"
                                                   placeholder="Qualification" name={"education"} onChange={(event) => {
                                                handleChange(event)
                                            }}/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label labels">Resume <span
                                                className="text-danger">*</span></label>
                                            <input type="file" className="form-control form-input"
                                                   onChange={(event) => {
                                                       fileInputHandler(event);
                                                   }} required/>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label labels">Cover Letter</label>
                                            <textarea className="form-control form-input" id="exampleFormControlInput1" rows={4}
                                                      placeholder="Cover Letter" name={"coverLetter"} onChange={(event) => {
                                                handleChange(event)
                                            }}/>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <button type="submit" className="button button_theme mb-3 w-100">Apply Now</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    </section>
}
