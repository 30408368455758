import React, {Component} from "react";
import "./GalleryLayout2.scss";
import ScrollAnimation from "react-animate-on-scroll";
import {GalleryProps} from "../../../../models/GalleryModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch as search} from "@fortawesome/free-solid-svg-icons";
import LazyLoad from "react-lazyload";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import Image from "../../../../components/imagePlaceholder/Image";
import {ShowPrevious} from "../../../../components/commonFunctions/gallery/ShowPrevious";
import {ShowNext} from "../../../../components/commonFunctions/gallery/ShowNext";
import Close from "../../../../components/commonFunctions/gallery/buttonIcons/Close";
import {PreviousNext} from "../../../../components/commonFunctions/gallery/buttonIcons/PreviousNext";

class GalleryLayout2 extends Component<GalleryProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            imageSelected: '',
            showLightBox: false,
            imageIndex: 0,
        };
        console.log("#27", this.props.data);

    }

    render() {
        return (
            <section id={this.props.htmlId}
                     className={`${this.props.themeClass} gallery-sec_l1 ptb_70 d-flex flex-column justify-content-center`}>
                <div className="container">
                    <div className="shape_cloud float"></div>
                    <span className="footer__shape"></span>
                    <ScrollAnimation animateIn='fadeInUp' animateOut='fadeInDown'>
                    {this.props.data['displayHeader'] ?
                        <div className="row justify-content-center">
                            <HeadingTitles subtitle={this.props.data['subtitle']} heading={this.props.data['title']}
                                           classesObject={{
                                               headingClass: 'heading-style',
                                               descriptionClass: 'sub-title'
                                           }} headingTag="h2" layout="layout_1"
                                           description={this.props.data['description']} alignment="center"/>
                        </div> : ''}
                </ScrollAnimation>
                    {
                        ((this.props.data?.view === "NORMAL")) ?
                            <div className="row ">
                                <div className={`d-grid ${this.props.data?.imageCols ===3 ?? 3 ? "gallery-slider__threegrid" : "gallery-slider__fourgrid"}`}>
                                {this.props.data?.photos.map((photo: any, index: number) =>
                                    <div key={photo.cid} style={{marginBottom: `${this.props.data?.titlePosition === "OVERLAY" ? "0px" : "20px"}`}}>
                                        <div className="gallery-wrapper"
                                             style={{marginBottom: `${this.props.data?.titlePosition === "OVERLAY" ? "0px" : "60px"}`}}>
                                            <div className="gallery_items " style={{
                                                height: `${this.props.data.height ? this.props.data['height'] : 120}px`,
                                                padding: `${this.props.data['padding'] ? this.props.data['padding'] : 7}px`,
                                                objectFit: this.props.data?.backgroundSize ?? "cover",
                                            }}>

                                                <div className={`${this.props.data?.titlePosition === "OVERLAY" ? "content-overlay" : "overlay"}`}
                                                     onClick={(e) => {
                                                    if (this.props.data['showZoomBtn']) {
                                                        this.setState({
                                                            ...this.state,
                                                            imageSelected: photo.url,
                                                            showLightBox: true
                                                        })
                                                    }
                                                }}></div>

                                                    <LazyLoad className="product-img" once offset={100}
                                                          placeholder={`https://asphaltservicesinc.com/wp-content/uploads/2017/07/placeholder.jpg`}>
                                                    <Image className="product-img"
                                                           placeholder={`https://asphaltservicesinc.com/wp-content/uploads/2017/07/placeholder.jpg`}
                                                           src={photo.url} style={{
                                                        height: `${this.props.data['height'] ? this.props.data['height'] : 120}px`,
                                                        objectFit: this.props.data?.backgroundSize ?? "cover",
                                                    }} loading='lazy' alt={""}></Image>
                                                </LazyLoad>
                                                {  ((this.props.data?.titlePosition === "OVERLAY")) ?
                                                    <div className="content-details fadeIn-bottom">
                                                        <h3 className="content-title">{photo.title}</h3>
                                                        {this.props.data['showZoomBtn'] ?
                                                            <div className="search-icon"
                                                                 onClick={(e) => this.setState({
                                                                     ...this.state,
                                                                     imageSelected: photo.url,
                                                                     showLightBox: true
                                                                 })}>
                                                                <FontAwesomeIcon icon={search} className="lock-icon icons"/>
                                                            </div> : ''
                                                        }
                                                    </div> : <div className='p-3 text-center img_title sub-title'><span>{photo.title}</span></div>}


                                            </div>

                                            </div>
                                    </div>
                                )}
                            </div>
                            </div>
                            :
                            <div className="row">
                                <div className="masonary overflow-hidden">
                                    {
                                        this.props.data?.photos.map((photo: any, index: number) => (
                                            <div className="pics" key={photo.cid}>
                                                <LazyLoad offset={100}>
                                                    {
                                                        ((this.props.data.disableHover)) ?
                                                            <div className={'overlay'} onClick={(e) => {
                                                                if (this.props.data['showZoomBtn']) {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        imageSelected: photo.url,
                                                                        showLightBox: true
                                                                    })
                                                                }
                                                            }}>

                                                            </div>
                                                            :
                                                            <div className="content-overlay1" onClick={(e) => {
                                                                if (this.props.data['showZoomBtn']) {
                                                                    this.setState({
                                                                        ...this.state,
                                                                        imageSelected: photo.url,
                                                                        showLightBox: true
                                                                    })
                                                                }
                                                            }}></div>
                                                    }

                                                    <img className="card-img-top" src={photo.url} style={{
                                                        width: "100%",
                                                        minHeight: `${this.props.data['height'] ? this.props.data['height'] : 250}px`,
                                                        padding: `${this.props.data['padding'] ? this.props.data['padding'] : 7}px`,
                                                        objectFit: this.props.data?.backgroundSize ?? "cover",
                                                    }} loading='lazy' alt={""}/>
                                                </LazyLoad>
                                                {  ((this.props.data?.titlePosition === "OVERLAY")) ?
                                                <div className="content-details1">
                                                    <h3 className="content-title1">{photo.title}</h3>
                                                </div>
                                                    :
                                                    <div className='p-3 text-center img_title bg-color'><span>{photo.title}</span></div>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                    }
                </div>
                {
                    this.state.showLightBox ?
                        <div id="lightbox" onClick={() => this.setState({...this.state, showLightBox: false})}>
                            <div style={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                            }} className="mt-4">
                                <Close onClick={() => this.setState({...this.state, showLightBox: false})}/>
                            </div>
                            {PreviousNext(true, (e)=> {
                                ShowPrevious(this.props.data?.photos, this.state.imageIndex, e, (res) => {
                                    this.setState({
                                        ...this.state,
                                        imageSelected: res?.imageSelected,
                                        imageIndex: res?.imageIndex,
                                    })
                                })
                            })}
                            <img id="lightbox-img" src={this.state.imageSelected} loading="lazy" alt={""}/>
                            {PreviousNext(false, (e)=> {
                                ShowNext(this.props.data?.photos, this.state.imageIndex, e, (res) => {
                                    this.setState({
                                        ...this.state,
                                        imageSelected: res?.imageSelected,
                                        imageIndex: res?.imageIndex,
                                    })
                                })
                            })}
                        </div>
                        : ''
                }

            </section>
        );
    }
}

export default GalleryLayout2;
