export class Constants {
    static API_BASE_URL = `${process.env.REACT_APP_API_END_POINT}`;
    static ACCESS_TOKEN = "accessToken";
    static REFRESH_TOKEN = "refreshToken";
    static IS_LOGGED_IN = "isLoggedIn";
    static USER_ID = "userId";
    static FIRST_NAME = "firstName";
    static LAST_NAME = "lastName";
    static EMAIL = "email";
    static MOBILE_NUMBER = "mobileNumber";
}
