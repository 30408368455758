import React, {Component, RefObject} from "react";
import "./Products.scss";
import {ProductsProps} from "../../../../models/BannerModel";
import {Link} from "react-router-dom";
import {ProductRepo} from "../../../../repo/ProductRepo";
import LazyLoad from "react-lazyload";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import ScrollAnimation from "react-animate-on-scroll";

class Products extends Component<ProductsProps, any> {
    form: RefObject<HTMLFormElement>;
    dropdown:RefObject<HTMLDivElement>;
    constructor(props: any) {
        super(props);
        this.state = {};
        this.getProducts();
        this.dropdown=React.createRef();
        this.form =React.createRef();
        // this.handle
    }

    params = this.props.match.params;
    products = [];

    getProducts() {
        let tagString = "";
        if(this.props?.data["tagIds"]) {
            this.props?.data["tagIds"].map((tag : any, index:number) => {
                if(this.props?.data["tagIds"].length === 1){
                    tagString = tag;
                } else {
                    if(index === 0){
                        tagString =  tag;
                    } else {
                        tagString = tagString + "," + tag;
                    }
                }
            });
        }
        ProductRepo.getProducts(undefined, this.params.id ?? "", tagString).then((data) => {
            this.products = data.data["content"];
            this.setState(() => ({}));
        }).catch((error) => {
            console.log("#25 Products:", error);
        });
    }

    render() {
        return (
            <section id={this.props.htmlId}
                     className={`${this.props.themeClass} products_l1 testingFontFamily ptb_80 position-relative`}>
                <div className="container">
                    <ScrollAnimation animateIn='fadeInUp'>
                    <div className="row justify-content-center cursor-pointer">
                        <HeadingTitles heading={this.props.data['title']}
                                       classesObject={{headingClass: 'heading-style', descriptionClass: 'sub-title'}}
                                       headingTag="h2" layout="layout_1" description={this.props.data['subtitle']}
                                       alignment="center"/>
                    </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='fadeInUp'>
                    <div className="row">
                        <div className="d-grid mx-auto insidewrapper__three-grid mt-4">
                            {
                                this.products.map((products: any, index) =>
                                        ///TODO: Important (Plz dont remove this commentted code)

                                        <Link style={{pointerEvents: (this.props.data?.clickable === true) ? "visible" : "none"}}
                                              to={`${this.params.id ? this.params.id + '/' : ''}product/${products?.id}`}
                                              className="featured-item">


                                            {/*   <Link to={`${this.params.id ? this.params.id + '/' : ''}product/${products?.id}`} className="featured-item">*/}
                                            <div className="text-center">
                                                <div className="item__img_wrapper position-relative mb-4 ">
                                                    <LazyLoad offset={100}>
                                                        <img src={products?.image?.url} alt="" height={"250px"}
                                                             style={{objectFit: "cover"}} loading="lazy"/>
                                                    </LazyLoad>
                                                    <div className="inner position-absolute"><h4
                                                        className="title heading-style mb-0">{products?.name}</h4></div>
                                                </div>
                                                {this.props.data.showPrice ?
                                                    <div className="item__price heading-style">
                                                        <span className="title-price">From</span>
                                                        <span className="price_value mx-2">₹{products?.price}</span>
                                                        <span className="unit">{products?.priceUnit}</span>
                                                    </div> : ''
                                                }
                                                {/*<div className="line">*/}
                                                {/*    <span></span>*/}
                                                {/*</div>*/}

                                                {/*<div dangerouslySetInnerHTML={{__html:products.description || ''}} className={'mt-1 mx-md-3 product__description mini-title'}></div>*/}
                                                <p className="mt-1 mx-md-3 product__description mini-title">{products?.description}</p>
                                            </div>
                                        </Link>
                                    // </div>
                                )
                            }
                        </div>
                    </div>
                    </ScrollAnimation>
                </div>
            </section>
        );
    }
}

export default Products;
