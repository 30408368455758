import "./HeaderRibbon.scss";
import {HeaderRibbonProps} from "../../../../models/BannerModel";
import {Component} from "react";

class HeaderRibbon extends Component<HeaderRibbonProps, any> {

    render() {
        return <header id={this.props.htmlId}
                       className={`${this.props.themeClass} site_widget headerRibbon-sec`}>
            <div className="header-top" style={{backgroundColor: this.props.data?.themeShade?.color ?? 'white'}}>
                <div className="container d-sm-flex align-items-center justify-content-between">
                    <p>{this.props.data['tagline']}</p>
                    <div className="contact-links">
                        <ul className="d-flex p-0 m-0 align-items-center justify-content-center justify-content-sm-end">
                            {this.props.data['email'] && <li>
                                <i className="bi bi-envelope-fill me-2"></i>
                                <a href={`mailto:${this.props.data['email']}`}>{this.props.data['email']}</a>
                            </li>}
                            {this.props.data['mobile'] && <li>
                                <i className="bi bi-telephone-fill me-2"></i>
                                <a href={`mailto:${this.props.data['mobile']}`}>{this.props.data['mobile']}</a>
                            </li>}
                        </ul>
                    </div>
                </div>
            </div>
        </header>

    }

}

export default HeaderRibbon;