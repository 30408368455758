import React, {Component} from "react";
import "./ourServicesLayout1.scss";
import {OurServicesProps} from "../../../../models/BannerModel";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import ScrollAnimation from "react-animate-on-scroll";


class OurServicesLayout1 extends Component<OurServicesProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    renderIconType = (type: string, icon: string) => {
        switch (type) {
            case "Material":
                return "material-icons";
            case "Bootstrap":
                return `bi bi-${icon}`;
            default:
                return "";
        }
    }

    ServiceCardWidget(service: any) {
        let numberOfColumn: number = this.props?.data?.columns;
        if (numberOfColumn === 4) {
            numberOfColumn = 3;
        } else if (numberOfColumn === 3) {
            numberOfColumn = 4
        }

        return <div key={service?.id} className={`col-md-${numberOfColumn} mb-sm-3`}>
            <div className=" position-relative text-center">
                <div className="service_content_wrapper border-0  rounded-4  text-white">
                    <div className="card-icon">
                        {service?.icon?.type ==='Image' ?
                            <img src={service.icon?.image?.url ?? ''} style={{height:'50px' , objectFit:'cover'}}/> :
                            <span
                            className={this.renderIconType(service.icon?.type ?? "", service.icon?.className ?? "")}>{service.icon?.type === "Bootstrap" ? null : service.icon?.className}</span>}
                    </div>
                    <div className="mt-3">
                        <div className="card-title mb-4">
                            <h2>{service?.title}</h2>
                        </div>
                        <div className="section-header">
                            <p>{service?.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    divisionSelector() {
        return this.props?.data?.services.map((value: any) => {
            return this.ServiceCardWidget(value);
        })
    }

    render() {
        return (
            <section id={this.props.htmlId}
                     className={`${this.props.themeClass} ourServices_l1 ptb_80 position-relative`}>
                <div className="container">
                    {/*<ScrollAnimation animateIn='ZoomIn'>*/}

                    <div className="row justify-content-center">
                        <ScrollAnimation animateIn='ZoomIn'>
                            <HeadingTitles heading={this.props.data['title']} classesObject={{
                                headingClass: 'heading-style',
                                descriptionClass: 'sub-title'
                            }} headingTag="h2" layout="layout_1" description={this.props.data['subtitle']}
                                           alignment="center"/>
                        </ScrollAnimation>
                        {
                            this.divisionSelector()
                        }
                    </div>
                    {/*</ScrollAnimation>*/}
                </div>
            </section>
        );
    }
}

export default OurServicesLayout1;
