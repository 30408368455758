import axios, {AxiosInstance} from 'axios';
import {SessionManager} from "./sessionManager";
import history from "../util/History";
import {AccountRepo} from "./AccountRepo";
import {Token} from "../models/Models";
import {Constants} from "./Constants";

export const axiosClient: AxiosInstance = axios.create({
    baseURL: Constants.API_BASE_URL,
    adapter: require('axios/lib/adapters/http')
});
axiosClient.interceptors.request.use((config) => {
    if (typeof localStorage !== "undefined") {
        let token = SessionManager.getAccessToken()
        if (token != null) {
            config.headers['Authorization'] = `bearer ${token}`;
        }
    }
    config.headers['client-id'] = '100';
    return config;
}, error => error);
axiosClient.interceptors.response.use(function (res) {
    return res;
}, function (error) {
    const request = error.config;
    if (401 === error.response?.status && !request._retry && SessionManager.getRefreshToken() && SessionManager.getRefreshToken() !== '') {
        request._retry = true;
        return AccountRepo.refreshAccessToken(SessionManager.getRefreshToken() || "").then((res: Token) => {
            SessionManager.updateSession(res.access_token, res.refresh_token);
            request.headers.Authorization = 'bearer ' + res.access_token;
            return axiosClient(request);
        }).catch((error: any) => {
            if (400 === error.response?.status || 401 === error.response?.status) {
                logOut();
            }
            return Promise.reject(error);
        })
    } else {
        return Promise.reject(error);
    }
});

const logOut = () => {
    SessionManager.clearSession();
    window.location.reload();
    history.push("/");
}


export default axiosClient;
