import {AlertBoxProps} from "../../models/BannerModel";
import React , {Component} from "react";

class AlertBox extends Component<AlertBoxProps ,any>{

    render(){
        return(
            <div className="alert-danger ptb_80 position-relative p-5">
                <h3 className="align-items-center">{this.props?.data?.layoutName}</h3>
                <p>Kindly check the validations in {this.props?.data?.type} to remove the errors</p>
            </div>
        );
    }
}
export default AlertBox;
