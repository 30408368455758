import React, {Component} from "react";
import "./FooterLayout2.scss";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faTwitter, faYoutube,faLinkedinIn,faInstagramSquare} from "@fortawesome/free-brands-svg-icons";
import {FooterProps} from "../../../../models/ContactUsModel";
import SocialIcon from "../../../../components/SocialIcon";
import logo from "../../../../assets/css/images/Listingfy-logo.png";
import {faCircle as dot} from "@fortawesome/free-solid-svg-icons";
import {faAngleUp as upArrow} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
class FooterLayout2 extends Component<FooterProps, any>{
    componentDidMount() {
    }

    // render() {
    //     return (
    //         <footer id={this.props.htmlId} className={`${this.props.themeClass} footer_l2 ptb_80 testingFontFamily`}>
    //             <div className="widgets_wrapper">
    //                 <div className="container">
    //                    <div className="row align-items-center">
    //                        <div className="col-md-2">
    //                            <div className="footer-content-wrapper1 widget widget_link">
    //                               <h4>Envato Level 13, 2 Elizabeth Victoria 3000 Australia</h4>
    //                                <div className="icon-wrapper mt-3">
    //                                    <SocialIcon url={this.props.data['facebookProfile']} icon={faFacebookF}/>
    //                                    <SocialIcon url={this.props.data['twitterProfile']} icon={faTwitter}/>
    //                                    <SocialIcon url={this.props.data['youtubeProfile']} icon={faYoutube}/>
    //                                    <SocialIcon url={this.props.data['linkedInProfile']} icon={faLinkedinIn}/>
    //                                    <SocialIcon url={this.props.data['instagramProfile']} icon={faInstagramSquare}/>
    //                                </div>
    //                            </div>
    //                        </div>
    //                        <div className="col-md-8">
    //                            <div className="footer-content-wrapper2 text-center mb-3">
    //                                <img src={logo} style={{height:65}} loading="lazy" alt={""}/>
    //                            </div>
    //                            <div className="heading_line text-center">
    //                                <h5 className="title">
    //                                    <span className="line line_l"> </span>
    //                                    PLEASE CALL US
    //                                    <span className="line line_r"> </span>
    //                                </h5>
    //                                <h2>+61 (0) 3 8376 6284</h2>
    //                            </div>
    //                        </div>
    //                        <div className="col-md-2">
    //                             <div className="widget_link widget">
    //                                 <ul className="list p-0">
    //                                     <li><FontAwesomeIcon icon={dot} className="icons"/>Services</li>
    //                                     <li><FontAwesomeIcon icon={dot} className="icons"/>Contact us</li>
    //                                     <li><FontAwesomeIcon icon={dot} className="icons"/>Our Team</li>
    //                                     <li><FontAwesomeIcon icon={dot} className="icons"/>Products</li>
    //                                 </ul>
    //                             </div>
    //                        </div>
    //                    </div>
    //                     <div className="row bottom_footer justify-content-center">
    //                         <FontAwesomeIcon icon={upArrow} className="upArrow"/>
    //                         <div className="copyright text-center">
    //                             © 2022  | All Rights Reserved | Powered by <b>ListingFy</b>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </footer>
    //     );
    // }
}
export default FooterLayout2;
