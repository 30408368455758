import React,{Component} from "react";
import error_500 from "../../assets/error_500.svg";

interface ServerErrorProps{
    statusCode:any,
    error:string
}
class ServerError extends Component<ServerErrorProps,any>{

    render(){
        return(
            <div className="error_load" style={{textAlign: "center"}}>
              <div className="error-container">
                  <div className="error_info">
                      <h1>Internal Server Error : {this.props.statusCode}</h1>
                      <p>{this.props.error}</p>
                  </div>
                  <div className="error-image">
                      <img src={error_500} loading="lazy" alt="error"/>
                  </div>
              </div>
            </div>
        );
    }
}
export default  ServerError;
