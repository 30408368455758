import React, {Component} from "react";
import {ContainerProps} from "../../models/BannerModel";
import "./Container.scss"
import history from "../../util/History";
import HeadingTitles from "../../themes/bsc/widgets/common/sectionHeading/HeadingTitles";

class Container extends Component<ContainerProps,any> {

    constructor(props: any) {
        super(props);
        this.state = {
            breadcrumbPath: [],
        }
    }
    componentDidMount() {
        this.setState(() => ({breadcrumbPath: this.props.breadCrumb}));
    }

    render() {
    return (
     <section  className={`${this.props.themeClass} container_l1 testingFontFamily ptb_80 position-relative`}>
         <div className="container">
                 <div className="heading-content text-center">
                     <div>
                         <h2 className="heading-style">{this.props.data['title']}</h2>
                         {this.props.data['subtitle'] ?
                                 <span>{this.props.data['subtitle']}</span>
                             :''
                         }
                         {
                             this.props.data?.content?.html ?  <div dangerouslySetInnerHTML={{__html: this.props.data?.content?.html || ''}}></div> :''
                         }
                         <ul className="breadcrumb_menu mt-5">
                             <li className="" onClick={() => {history.push(`/preview/${this.props.siteId}`);}}>Home</li>
                             { this.props?.breadCrumb?.map((res: any) =>
                                 <li>{res}</li>)
                             }
                         </ul>
                     </div>
                 </div>
         </div>
         <div className="absolute">
             <div className="shape overflow-hidden text-white dark:text-slate-900">
                 <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                 </svg>
             </div>
         </div>
     </section>
    )}
}

export default Container;
