import {Component, FormEvent} from "react";
import {ContactUsProps} from "../../../../models/ContactUsModel";
import "./ContactUsLayout2.scss";
import React, {RefObject} from "react";
import axiosClient from "../../../../config/AxiosRestClient";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEnvelope as message,
    faMapMarkerAlt as home,
    faPaperPlane as sendMessage,
    faPhoneAlt as mobile
} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import "./ContactUsLayout3.scss";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";


interface ContactUsL3State {
    usermessage?: UserMessage,
    processing: boolean,
    messageSent: boolean,
    name: string,
    email: string,
    mobile: string,
    subject: string,
    message: string,
}

interface UserMessage {
    message: string
    type: string
}

class ContactUsLayout3 extends Component<ContactUsProps, ContactUsL3State> {
    form: RefObject<HTMLFormElement>
    dropdown: RefObject<HTMLDivElement>

    constructor(props: ContactUsProps) {
        super(props);
        this.dropdown = React.createRef();
        this.state = {
            processing: false,
            messageSent: false,
            name: '',
            email: '',
            message: '',
            mobile: '',
            subject: '',
        }
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        const target = event.target;
        const name = target.name;
        const value = this.extractEventValue(target);
        // @ts-ignore
        this.setState({
            [name]: value
        });
    }

    private extractEventValue(target: any) {
        if (target.type === 'checkbox') {
            return target.checked;
        } else if (target.type === 'file') {
            return target.files!![0];
        } else {
            return target.value;
        }
    }

    getPayload = (): any => {
        const payload = {
            'siteId': this.props.siteId,
            'email': this.state.email,
            'name': this.state.name,
            'mobile': this.state.mobile,
            'subject': this.state.subject,
            'message': this.state.message,
        }
        return payload;
    }

    contactUser = () => {
        this.setState({processing: true});
        axiosClient.post(`/listingfy-service/website/` + this.props.siteId + '/contact-us', this.getPayload()).then((res) => {
            if (res.status === 200) {
                this.setState(() => ({usermessage: {message: "Message Sent Successfully", type: "success"}}));
                this.setState({processing: false, messageSent: true});
            } else {
                this.setState({usermessage: {message: "Unknown error sending message: " + res.status, type: "error"}});
                this.setState({processing: false, messageSent: false});
            }
        }).catch((error: any) => {
            console.log("#96 Contact Us Layout 3:", error);
            this.setState(() => ({usermessage: {message: "Error occured", type: "error"}}))
            this.setState({processing: false, messageSent: false});
        });
    }

    submitContactForm = (event: FormEvent) => {
        event.preventDefault();
        this.contactUser();
    }

    render() {
        return <section id={this.props.htmlId} className={`${this.props.themeClass} contactUs_l3 testingFontFamily ptb_50`}>
            <div className="container">
                <ScrollAnimation animateIn='fadeInUp'>
                    <div className="row justify-content-center mb-3">
                    <div className="section-heading text-center">
                        <h2 className="heading-style">{this.props.data['title'] ?? "We'd love to hear from you!"}</h2>
                        <span className="label-line"></span>
                        <p className="d-none d-sm-block mt-2 sub-title">{this.props.data['subtitle'] ?? "Fill out this short form and someone from the VazaHealth team will be in touch!"}</p>
                    </div>
                </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInDown'>
                    <div className="row justify-content-between">
                    <div className="col-md-4 contactUs_gradient">
                        <div className="contact-us">
                            <h2 className="mb-3 heading-style text-center" style={{fontWeight:'600'}}>Reach Us</h2>

                            { this.props.data['phone']?
                            <a href={`tel:${this.props.data['phone']}`} className="contact-us-items media d-flex align-items-center py-4 sub-title">
                                <div className="social-icon me-4">
                                        <FontAwesomeIcon icon={mobile as IconProp} className="lock-icon icons"/>
                                </div>
                                <span className="media-body align-self-center">{this.props.data['phone']}</span>
                            </a>:''}

                            { this.props.data['whatsapp']?
                            <a  href={`https://wa.me/91${this.props.data['whatsapp']}?text=I'm%20inquiring%20about%20the%20website%20building`} target={"_blank"}
                                className="contact-us-items media d-flex align-items-center py-4 sub-title">
                                <div className="social-icon me-4">
                                    <FontAwesomeIcon icon={faWhatsapp as IconProp} className="lock-icon icons"/>
                                </div>
                                <span className="media-body align-self-center">{this.props.data['whatsapp']}</span>
                            </a> :''}

                            { this.props.data['email']?
                                <a href={`mailto:${this.props.data['email']}`}
                                   className="contact-us-items media d-flex align-items-center py-4 sub-title">
                                <div className="social-icon me-4">
                                    <FontAwesomeIcon icon={message as IconProp} className="lock-icon icons"/>
                                </div>
                                <span className="media-body align-self-center">{this.props.data['email']}</span>
                            </a>:''}

                            { this.props.data['location']?
                            <a href={'/#'} className="contact-us-items media d-flex align-items-center py-4 sub-title">
                                <div className="social-icon me-4">
                                    <FontAwesomeIcon icon={home as IconProp} className="lock-icon icons"/>
                                </div>
                                <span className="media-body align-self-center">{this.props.data['location']}</span>
                            </a>:''}
                        </div>
                    </div>
                    <div className="col-md-8" style={{boxShadow: "rgb(207 207 207) 1px 1px 12px",backgroundColor: "white",borderBottomRightRadius:70}}>
                        <div className="contact-box text-center position-relative">
                            {this.state.messageSent ?
                                <div id="contact-form-box" className={"alert alert-success"}>Query submitted successfully</div> :
                                <form id="contact-form" style={{padding: "50px 20px 0px 20px"}} onSubmit={this.submitContactForm} role="form">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="contact-form-field">
                                                <label htmlFor="nameInput" className="contact-form-label">Name</label>
                                                <input type="text" className="contact-form-input"
                                                       name={"name"}
                                                       placeholder="Your Name here"
                                                       onChange={event => this.handleChange(event)} required/>
                                            </div>
                                            <div className="row contact-form-field">
                                                <div className="col-7">
                                                    <div className="form-group">
                                                        <label htmlFor="nameInput" className="contact-form-label">Email</label>
                                                        <input type="email" className="contact-form-input"
                                                               name={"email"}
                                                               placeholder="xyz@email.com"
                                                               onChange={event => this.handleChange(event)}
                                                               />
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <div className="form-group">
                                                        <label htmlFor="nameInput" className="contact-form-label">Mobile</label>
                                                        <input type="text" className="contact-form-input"
                                                               name={"mobile"}
                                                               placeholder="XXXXX-XXXXX" maxLength={10} pattern={"[0-9]*"}
                                                               onChange={event => this.handleChange(event)} required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group contact-form-field">
                                                <label htmlFor="nameInput" className="contact-form-label">Subject</label>
                                                <input type="text" className="contact-form-input"
                                                       name={"subject"}
                                                       placeholder="Your subject here"
                                                       onChange={event => this.handleChange(event)}/>
                                            </div>
                                        </div>
                                        <div className="col-12 contact-form-field">
                                            <div className="form-group">
                                                <label htmlFor="nameInput" className="contact-form-label">Message</label>
                                                    <textarea className="contact-form-input"
                                                              name={"message"}
                                                              placeholder="Describe your reason for contact here"
                                                              onChange={event => this.handleChange(event)}
                                                              required/>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-12 d-flex justify-content-end" style={{paddingTop : "30px"}}>
                                            <button type="submit" disabled={this.state.processing} className="send_btn">
                                                {
                                                    this.state.processing ?
                                                        (<div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>) :<div>
                                                        <FontAwesomeIcon icon={sendMessage as IconProp}/><label className="label1" htmlFor="">Send message</label>
                                                    </div>
                                                }
                                            </button>
                                            {this.state.usermessage?.type === 'error' ?
                                                <div
                                                    className={"alert alert-danger"}>{this.state.usermessage?.message}</div> : null}
                                        </div>
                                    </div>
                                </form>}
                        </div>
                    </div>
                </div>
                </ScrollAnimation>
            </div>
        </section>;
    }
}

export default ContactUsLayout3;
