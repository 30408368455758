import {headingTitleProps} from "../../../../../models/commonModel";
import "./HeadingTitles.scss";

export default function HeadingTitles(props: headingTitleProps) {

    const tagSwitcher = (tag: string, heading: string, headingClass?: string) => {
        switch (tag) {
            case 'h1':
                return <h1 className={headingClass}>{heading}</h1>;
            case 'h2':
                return <h2 className={headingClass}>{heading}</h2>;
            case 'h3':
                return <h3 className={headingClass}>{heading}</h3>;
            case 'h4':
                return <h4 className={headingClass}>{heading}</h4>;
            case 'h5':
                return <h5 className={headingClass}>{heading}</h5>;
            case 'h6':
                return <h6 className={headingClass}>{heading}</h6>;
            default:
                return <h1 className={headingClass}>{heading}</h1>;
        }
    }

    return (
        <>
            {(props.layout === 'layout_1') ?
                <div className={`section-heading__${props.layout} text-${props.alignment}`}>
                    {tagSwitcher(props.headingTag, props.heading, props.classesObject?.headingClass)}
                    {props.subtitle || props.description ?<span className="label-line"></span>:<></>}
                    {props.subtitle && <p className={props.classesObject?.descriptionClass}>{props.subtitle}</p>}
                    {props.description && <p className={props.classesObject?.descriptionClass}>{props.description}</p>}
                </div>
                :
                <div className={`mb-2 section-heading__${props.layout}`}>
                    <div className="mb-space">
                        {props.subtitle || props.description ?<span className="label-line"></span>:<></>}
                        {/*<span className={props.classesObject?.descriptionClass}>{props.subtitle}</span>*/}
                        <span className={props.classesObject?.descriptionClass}>{props.description}</span>
                    </div>
                    {tagSwitcher(props.headingTag, props.heading, props.classesObject?.headingClass)}
                </div>
            }
        </>
    );
};
