import HeaderUtils from "../../../themes/bsc/widgets/header/HeaderUtils/utils";

export function TargetLocation (sticky : boolean, location: any, htmlId: string) {
    return new Promise((resolve) => {
        if (sticky) {
            window.addEventListener("scroll", () => {
                if (window.scrollY > 200) {
                    document.getElementById(htmlId)?.classList.add("navbar-fixed");
                } else {
                    document.getElementById(htmlId)?.classList.remove("navbar-fixed");
                }
            });
        }
        const currentPath = location.pathname+location.hash;
        const locations = HeaderUtils.slpitDataType(currentPath, '/');
        const targetLocation = locations[locations.length - 1];
        resolve(targetLocation);
    })
}
