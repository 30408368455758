import React, {useEffect, useState} from "react";
import {Container, Nav, Navbar, Offcanvas} from "react-bootstrap";
import "./Header_common.scss";
import {useLocation} from "react-router";
import {MenuItems} from "./MenuItems/MenuItems";
import {Navbrand} from "./Navbrand/Navbrand";
import {TargetLocation} from "../../../../components/commonFunctions/header/TargetLocation";


export const HeaderLayout2 = (props: any) => {
    const [state, setState] = useState<Header2State>({
        name: '',
        email: '',
        menuItems: props.data?.menus,
        activeMenu: '',
        hashCode: '',
    });
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        setIsOpen(false);
        TargetLocation(props?.data?.sticky, location, props?.htmlId).then((target) => {
            setState((prevState: any) => ({...prevState, activeMenu: target}));
        })
    }, [location.pathname,location.hash]);

    return (
        <div>
            {['lg'].map((expand) => (
                <Navbar expanded={isOpen} id={props.htmlId} className={`${props.themeClass} testingFontFamily common_navlayout`}
                        expand={expand}>
                    <Container className="right_navlayout__menu_wrapper">
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={()=> setIsOpen(!isOpen)}/>
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                            className="right_navlayout__canvas_wrapper"
                        >
                            <Offcanvas.Header className="right_navlayout__canvas_header">
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    <Navbrand {...props} />
                                </Offcanvas.Title>
                                <div onClick={()=>setIsOpen(!isOpen)} aria-hidden="true" className="cursor-p close_icon fs-42">&times;</div>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-start flex-grow-1 pe-3">
                                    <MenuItems menuItemsList={state.menuItems} pathName={props.location.pathname}
                                               toggleMenu={() => setIsOpen(false)}
                                               pageId={props.match.params.id} ulClassName={"main-menu menu"}
                                               activeMenu={state.activeMenu}/>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                        <Navbrand {...props} />
                    </Container>
                </Navbar>
            ))}
        </div>
    );
}

export default HeaderLayout2;
