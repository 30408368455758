import {useEffect, useState} from "react";
import ReactGA from "react-ga";

export function useAnalytics () {
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(()=>{
        ReactGA.initialize('G-SGXME6X5PL');
        setInitialized(true);
    },[]);

    return {
        initialized
    }
}
