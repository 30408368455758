import React, {Component} from "react";
import "./FooterLayout3.scss";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons/faFacebookF";
import {faTwitter, faYoutube, faLinkedinIn, faInstagramSquare} from "@fortawesome/free-brands-svg-icons";
import {FooterProps} from "../../../../models/ContactUsModel";
import SocialIcon from "../../../../components/SocialIcon";
import {faPaperPlane as email, faMapMarkerAlt as location, faPhone as calling} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UniversalReadMoreButton} from "../../../../components/UniversalReadMoreButton/UniversalReadMoreButton";

class FooterLayout3 extends Component<FooterProps, any> {
    componentDidMount() {
    }

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: FooterProps) {
        super(props);
    }

    render() {
        return (
            <footer id={this.props.htmlId} className={`${this.props.themeClass} footer_layout_3 testingFontFamily`}
                    style={{
                        backgroundImage: `url(https://s3.carvia.tech/dl/ddg6d588-c2e6-4b23-9bc0-86e3b0ffb3bg/19a78-98016a6.png)`,
                        backgroundSize: "cover",
                    }}>
                <div className="footer_layout_3__wrapper">
                    <div className="container">
                        <div className="shape_moon float"></div>
                        <div className="row">
                            <div className="col-xl-4 col-md-12 col-sm-12">
                                <div className="footer_layout_3__footer-logo">
                                    <div className="footer__widget-title">
                                        {
                                            this.props?.data?.image?.url ?
                                                <img src={this.props?.data?.image?.url} className="mb-3 h-auto"/>
                                                : ""
                                        }
                                        <h2 className="heading-style">{this.props?.data?.title}</h2>
                                    </div>
                                    {/*<p>{this.props?.data?.description}</p>*/}
                                    {
                                        this.props.data?.desc?.html ?
                                            <div dangerouslySetInnerHTML={{__html:this.props.data?.desc?.html || ''}} className={'sub-title'}></div>
                                            :

                                            <p className="sub-title">{this.props.data?.description}</p>

                                    }
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 col-sm-6">
                                <div className="footer_widget_link">
                                    <div className="footer__widget-title">
                                        <h4 className="heading-style">Quick Links</h4>
                                    </div>
                                    <div className="menu-footer-menu-1-container">
                                        <ul className="menu-footer-menu-1 p-0 m-0">
                                            {this.props.data.quickLinks.map(value => {
                                                return <li className="qick_links">
                                                    <UniversalReadMoreButton payload={value} pathName={value.value}
                                                                             siteId={this.props.channelId}/>
                                                </li>
                                            })}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 col-sm-6">
                                <div className="footer_widget_link">
                                    <div className="footer__widget-title">
                                        <h4 className="heading-style">{this.props?.data?.contactSectionLabel || 'Stay Connected'} </h4>
                                    </div>
                                    <div className="menu-footer-menu-1-container">

                                        <ul className="menu-footer-menu-1 p-0">
                                            {
                                                this.props?.data?.layout1Config?.addresses.map(value => {
                                                    return <li className="qick_links"><a
                                                        className="theme-link"><FontAwesomeIcon icon={location}
                                                                                                className="icons me-2"/>{value}
                                                    </a>
                                                    </li>
                                                })
                                            }

                                            <li className="qick_links"><a className="theme-link"><FontAwesomeIcon
                                                icon={email}
                                                className="icons me-2"/>{this.props?.data?.layout1Config?.email}</a>
                                            </li>
                                            <li className="qick_links"><a className="theme-link"><FontAwesomeIcon
                                                icon={calling}
                                                className="icons me-2"/>{this.props?.data?.layout1Config?.contact}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer__bottom">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div
                                            className="footer__content text-center d-flex align-items-baseline justify-content-between">
                                            <div className="footer__copyright">
                                                <p>Copyright © 2022 <a
                                                    className="company_name">{this.props.data.companyName}</a></p>
                                            </div>
                                            <ul className="icon-wrapper mt-3">
                                                <li><SocialIcon url={this.props.data.facebookProfile}
                                                                icon={faFacebookF}/></li>
                                                <li><SocialIcon url={this.props.data.twitterProfile} icon={faTwitter}/>
                                                </li>
                                                <li><SocialIcon url={this.props.data.youtubeProfile} icon={faYoutube}/>
                                                </li>
                                                <li><SocialIcon url={this.props.data.linkedInProfile}
                                                                icon={faLinkedinIn}/></li>
                                                <li><SocialIcon url={this.props.data.instagramProfile}
                                                                icon={faInstagramSquare}/></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterLayout3;

//
// <footer id={this.props.htmlId} className={`${this.props.themeClass} footer_l3 testingFontFamily`}
//         style={{
//             backgroundImage: `url(https://s3.carvia.tech/dl/ddg6d588-c2e6-4b23-9bc0-86e3b0ffb3bg/19a78-98016a6.png)`,
//             backgroundSize: "cover",
//         }}>
//     <div className="widgets_wrapper ptb_80 pb-3">
//         <div className="container">
//             <div className="row">
//                 <div className="col-xl-4 col-md-10 col-sm-12">
//                     <div className="footer__widget-logo">
//                         <img src={this.props?.data?.image?.url} style={{height: 65}} loading="lazy"/>
//                         <h2>{this.props?.data?.title}</h2>
//                         <p>{this.props?.data?.description}</p>
//                     </div>
//                 </div>
//                 <div className="col-xl-4 col-md-6 col-sm-6">
//                     <div className="footer_widget_link">
//                         <div className="footer__widget-title">
//                             <h4 className="heading-style">Quick Links</h4>
//                         </div>
//                         <div className="menu-footer-menu-1-container">
//                             <ul className="menu-footer-menu-1 p-0">
//                                 {this.props.data.quickLinks.map(value => {
//                                     return <li><UniversalReadMoreButton payload={value} pathName={value.value}
//                                                                         siteId={this.props.channelId}/></li>
//                                 })}
//
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-xl-4 col-md-6 col-sm-6">
//                     <div className="footer_widget_link">
//                         <div className="footer__widget-title">
//                             <h4 className="heading-style">Stay Connected</h4>
//                         </div>
//                         <div className="menu-footer-menu-1-container">
//
//                             <ul className="menu-footer-menu-1 p-0">
//                                 {
//                                     this.props?.data?.layout1Config?.addresses.map(value => {
//                                         return <li><FontAwesomeIcon icon={location}
//                                                                     className="icons me-2"/>{value}
//                                         </li>
//                                     })
//                                 }
//
//                                 <li><FontAwesomeIcon icon={email}
//                                                      className="icons me-2"/>{this.props?.data?.layout1Config?.email}</li>
//                                 <li><FontAwesomeIcon icon={calling} className="icons me-2"/>+91-7814058215</li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="footer__bottom">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-md-12">
//                             <div className="footer__content text-center">
//                                 <div className="footer__copyright">
//                                     <p>Copyright © 2022 <a className="company_name">{this.props.data.companyName}</a></p>
//                                 </div>
//                                 <ul className="icon-wrapper mt-3">
//                                     <li><SocialIcon url={this.props.data.facebookProfile} icon={faFacebookF}/></li>
//                                     <li><SocialIcon url={this.props.data.twitterProfile} icon={faTwitter}/></li>
//                                     <li><SocialIcon url={this.props.data.youtubeProfile} icon={faYoutube}/></li>
//                                     <li><SocialIcon url={this.props.data.linkedInProfile} icon={faLinkedinIn}/></li>
//                                     <li><SocialIcon url={this.props.data.instagramProfile} icon={faInstagramSquare}/></li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </footer>
