import React from "react";
import "./SubmenuItem.scss"
import {MenuItem} from "./MenuItem";

export type MenuItemDto = { id: string, label: string, children: [], page: string, section: string, active: boolean }
export const MenuItems = (props: { menuItemsList: MenuItemDto[], pathName: string, pageId: string, ulClassName: string, activeMenu?: string, toggleMenu?:any }) => {

    const passbridge = () => {
        props.toggleMenu();
    }

    return <div className={`d-lg-inline-flex m-0 p-0 ${props.ulClassName}`}>
        {props.menuItemsList?.map((item: MenuItemDto, index: number) => <MenuItem handleOffCanvas={passbridge} item={item} activeMenu={props.activeMenu} pathName={props.pathName} pageId={props.pageId} />
        )}
    </div>
}


