import React, {Component} from "react";
import {BannerProps} from "../../../../models/BannerModel";
import './BannerLayout3.scss';
import {Carousel} from "react-bootstrap";
import 'animate.css';

class BannerLayout3 extends Component<BannerProps, any> {
    slidesData = [];

    constructor(props: any) {
        super(props);

        this.setItems();
        this.state = {
            name: '',
            description: '',
        };
    }

    setItems = () => {
        let slides: any = [];
        this.props.data?.slides.map((slide: any) => {
            if (slide.active) {
                slides.push(slide);
            }
        });
        this.slidesData = slides;
    }

    render() {
        return <section id={this.props.htmlId} className={`${this.props.themeClass} banner-sec_l3`}>
            <Carousel indicators={false} controls={this.slidesData?.length > 1}>
                {this.slidesData?.map((slide: any, index: any) =>
                    <Carousel.Item key={index} interval={(this.slidesData?.length === 1) ? null : this.props.data?.slideDelay}>
                        <div className="mainbannerwrap" style={{
                            backgroundImage: ` url(${slide['url']})`,
                        }}>
                            <div className={` ${this.props.data.showGridEffect ? 'banner__grid' : 'banner__overlay' }`}
                                 style={{
                                     backgroundColor: `rgba(0,0,0,${(this.props.data?.overlayOpacity / 10) ?? 0.7})`,
                            }}></div>
                            <div className="container">
                                <div className="row">
                                    <div className="content-wrapper text-center">
                                        <h1 className="animate fadeInLeft three heading-style text-uppercase">{slide['title']}</h1>
                                        <span className="label-line"></span>
                                        <p className="animate fadeInRight three sub-title">{slide['description']}</p>
                                        {/*<button className="button button_theme animate fadeInUp three">Explore more</button>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="arrow">
                                <span></span>
                                <span></span>
                                <span></span>
                                <h6 className="text-white sub-title">Scroll Down</h6>
                            </div>
                        </div>
                    </Carousel.Item>
                )}
            </Carousel>
        </section>;
    }

}

export default BannerLayout3;
