import React, {Component} from 'react';
import "./OurTeam.scss";
import {OurTeamProps} from "../../../../models/BannerModel";
import {faGithub, faLinkedinIn, faStackOverflow} from "@fortawesome/free-brands-svg-icons";
import SocialIcon from "../../../../components/SocialIcon";
import LazyLoad from "react-lazyload";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import ScrollAnimation from "react-animate-on-scroll";

class OurTeam extends Component<OurTeamProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (<section id={this.props.htmlId}
                         className={`${this.props.themeClass} team_sec testingFontFamily text-center`}>
            <div className="container">
                <ScrollAnimation animateIn='fadeInDown'>
                    <HeadingTitles heading={this.props.data['title']} classesObject={{
                        headingClass: 'heading-style mb-0',
                        descriptionClass: 'sub-title text-muted'
                    }} layout="layout_1" headingTag="h2" description={this.props.data['subtitle']} alignment="left"/>
                </ScrollAnimation>

                <ScrollAnimation animateIn='fadeInUp'>

                    <div className="row justify-content-center">
                        {this.props.data.members?.map((member: any) =>

                            <div className="col-lg-3 col-md-6">
                                <div className="single-team-inner">
                                    <div className="team_img">
                                        <LazyLoad offset={100}>
                                            <img className='logo' alt='profile-logo' src={member.image['url']}
                                                 loading="lazy"/>
                                        </LazyLoad>
                                    </div>
                                    <div className="team_content">
                                        {member.designation ?
                                            <h5 className="mini-title">{member.designation}</h5> : ''}
                                        <h6 className="sub-title">{member.name}</h6>
                                        <span className="text-muted title_description">{member.about}</span>
                                        <ul className="social-media mt-4">
                                            <li>
                                                <SocialIcon url={member.githubProfile} icon={faGithub}/>
                                                <SocialIcon url={member.stackoverflowProfile} icon={faStackOverflow}/>
                                                <SocialIcon url={member.linkedinProfile}
                                                            icon={faLinkedinIn}></SocialIcon>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </ScrollAnimation>

            </div>
        </section>);
    }
}

export default OurTeam;
