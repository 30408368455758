import React, {Component} from "react";
import './product_bsc.scss'
import {ComponentProps, DefaultMatchProps} from "../../../../util/CommonDto";
import Products from "../../../../screens/products/Products";
import axiosClient from "../../../../config/AxiosRestClient";
import LazyLoad from "react-lazyload";

interface ProductBscState{
    product:[],
}

class ProductBsc extends Component<any, ProductBscState>{
        constructor(props: ComponentProps<DefaultMatchProps, Products>) {
            super(props);
            this.state={
                product:[],
            };
        }
    componentDidMount() {
        this.fetchAllProducts();
    }
    fetchAllProducts() {
        axiosClient.get(`/listing-service/buy-sell`).then(response => {
            this.setState(() => ({product: response.data.content}));
        }).catch((err) => {
            console.log("#26 Product Bsc:", err);
        });
    }
    render() {
        return <div className="row row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xxl-4 mt-3 justify-content-start">
            {
                this.state.product.map(product => (<div className="col mb-3" key={product['id']}>
                        <div className="card-header p-0">
                            <LazyLoad offset={100}>
                            <img className="card-img" src={product['picture']} loading="lazy" alt={""}/>
                            </LazyLoad>
                        </div>
                        <div className="card-body text-start">
                            <h6 className="title">{product['name']}</h6>
                            <p className="sub-title">{product['location']}</p>
                        </div>
                </div>))
            }
        </div>
    }
}

export default ProductBsc;
