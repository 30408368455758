import React, {Component} from "react";
import {CounterProps} from "../../../../models/BannerModel";
import {Counter} from "./Counter";
import './CounterLayout1.scss';
import ScrollAnimation from "react-animate-on-scroll";


class CounterLayout1 extends Component<CounterProps, any> {

    sectionStyle: any = {
        backgroundImage: `url(${this.props?.data?.bgImage.url})`,
    }

    render() {
        return <section id={this.props?.htmlId}
                        className={`${this.props?.themeClass} counter_l1`} style={this.props?.data?.bgImage.url ? this.sectionStyle : {}}>
            <div id="counter">
                <div className="container">
                    <ScrollAnimation animateIn='fadeInLeft'>
                        <div className="row justify-content-center">
                        {
                            this.props?.data?.counters?.map((counter: any) => {
                                return <Counter title={counter?.title} target={counter?.value} suffix={counter?.suffix}/>
                            })
                        }
                    </div>
                    </ScrollAnimation>
                </div>
            </div>
        </section>;
    }
}

export default CounterLayout1;
