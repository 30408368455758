import React, {Fragment, useEffect} from 'react';
import "./assets/css/CustomBootstrap.scss";
import './App.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import {Route, Switch} from "react-router";
import Loadable from "react-loadable-visibility/loadable-components";
import {Loader} from "./components/loader/loader/Loader";
import TenantIndex from './Tenant/TenantIndex';
import {useAnalytics} from "./components/analytics/UseAnalytics";
import ReactGA from "react-ga";
import {Toaster} from "react-hot-toast";

const loginModal = Loadable(
    () => import('./screens/login/loginModal'),
    {fallback: <Loader/>,});
const ManageProfile = Loadable(
    () => import('./screens/manage/ManageProfile'), {
        fallback: <Loader/>,
    });
const ListingIndex = Loadable(
    () => import('./screens'), {
        fallback: <Loader/>,
    });

function App(props: any) {
    const {initialized} = useAnalytics();

    useEffect(() => {
        ReactGA.initialize('G-0DKT6XF0ED');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <Fragment>
            <Toaster position="bottom-right" toastOptions={{
                success: {
                    style: {
                        background: 'green',
                        color: 'white',
                        fontSize: "18px"
                    },
                },
                error: {
                    style: {
                        background: 'red',
                        color: "white",
                        fontSize: "18px"
                    },
                },
            }}/>
            <Switch>
                <Route exact path="/login" component={loginModal}/>
                <Route exact path="/manage-profile" component={ManageProfile}/>
                <Route exact path="/preview/:id*" component={TenantIndex}/>
                <Route exact path="/*" component={
                    window.location.hostname === "sites.listingfy.in" ||
                    window.location.hostname === "localhost" || window.location.hostname === "192.168.1.50" ? ListingIndex : TenantIndex}/>
            </Switch>
        </Fragment>
    );
}

export default App;
