import React, {useRef, useState} from "react";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import "./FixedGallery.scss";
import {FixedGalleryProps} from "../../../../models/GalleryModal";
import ScrollAnimation from "react-animate-on-scroll";

export const FixedGallery = (props: FixedGalleryProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const fx_main_wrapper = useRef<HTMLDivElement>(null);

    const changeStatus = (panelStatus: boolean, currentIndex: number) => {
        if (panelStatus) {
            fx_main_wrapper?.current?.children[currentIndex].classList.remove("active");
            setTimeout(function () {
                fx_main_wrapper?.current?.classList.remove("fx_gallery__stage--has-active");
                fx_main_wrapper?.current?.children[currentIndex].classList.remove("animating");
                fx_main_wrapper?.current?.classList.add("fx_gallery__stage");
                fx_main_wrapper?.current?.children[currentIndex].classList.remove("active");
            }, 1000);
        } else {
            fx_main_wrapper?.current?.classList.remove("fx_gallery__stage");
            fx_main_wrapper?.current?.children[currentIndex].classList.add("animating");
            fx_main_wrapper?.current?.classList.add("fx_gallery__stage--has-active");
            setTimeout(function () {
                fx_main_wrapper?.current?.children[currentIndex].classList.add("active");
            }, 1000);
        }
        setIsOpen(!panelStatus);
    }

    return <section id={props?.htmlId}
                    className={`${props.themeClass} fx_gallery w-100 m-0 position-relative`}>
        <div className="container">
            <ScrollAnimation animateIn='fadeInUp'>
            <HeadingTitles heading={props.data?.title ?? ""} classesObject={{
                headingClass: 'heading-style mb-0',
                descriptionClass: 'sub-title text-muted'
            }} layout="layout_1" headingTag="h2" description={props.data?.subtitle ?? ""} alignment="center"/></ScrollAnimation>
            <div className="row mt-2">
                <div ref={fx_main_wrapper} className="d-flex align-items-center justify-content-center flex-row fx_gallery__stage">
                    {
                        props?.data?.images?.map((img: any, index: number) => {
                            if(index<4) {
                                return  <div key={index} className="fx_gallery__panel position-relative overflow-hidden" onClick={(() => changeStatus(isOpen,index))}>
                                            <div className="fx_gallery__image-wrapper h-100 w-100 position-absolute abs-fill">
                                                <img className="fx_gallery__image h-100 w-100 position-absolute abs-fill"
                                                    loading="lazy"
                                                    src={img?.url}
                                                    alt={img?.title}
                                                />
                                            </div>
                                            <div className="fx_gallery__inner">
                                                <div className="fx_gallery__close"
                                                    onClick={(() => changeStatus(isOpen, index))}>
                                                    <i className="fi fi-rr-cross-circle"></i>
                                                </div>
                                                <div className="fx_gallery__title">{img?.title}</div>
                                                <div className="fx_gallery__content">
                                                    <div className="fx_gallery__description">{img?.description}</div>
                                                    <a href={`${img?.link}`} target={"_blank"}
                                                    className="button button--yellow fx_gallery__link">find out more</a>
                                                </div>
                                            </div>
                                        </div>;
                            }
                        })}
                </div>
            </div>
        </div>
    </section>
}
