import {Component, FormEvent} from "react";
import {ContactUsProps} from "../../../../models/ContactUsModel";
import "./ContactUsLayout2.scss";
import React, { RefObject} from "react";
import axiosClient from "../../../../config/AxiosRestClient";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope as message, faHome as home, faPhone as phone} from "@fortawesome/free-solid-svg-icons";
import LoaderButton from "../../../../components/LoaderButton";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import ScrollAnimation from "react-animate-on-scroll";

interface ContactUsL2State{
    usermessage?: UserMessage,
    processing: boolean,
    messageSent: boolean,
    name: string,
    email: string,
    mobile: string,
    subject:string,
    message:string,

}
interface UserMessage {
    message: string
    type: string
}

class ContactUsLayout2 extends Component<ContactUsProps,ContactUsL2State>
{
    form: RefObject<HTMLFormElement>
    dropdown: RefObject<HTMLDivElement>
    constructor(props: ContactUsProps)
    {
        super(props);
        this.dropdown = React.createRef();
        this.state ={
            processing: false,
            messageSent: false,

            name: '',
            email: '',
            message:'',
            mobile: '',
            subject:'',
        }
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        const target = event.target;
        const name = target.name;
        const value = this.extractEventValue(target);
        // @ts-ignore
        this.setState({
            [name]: value
        });
    }

    private extractEventValue(target: any) {
        if (target.type === 'checkbox') {
            return target.checked;
        } else if (target.type === 'file') {
            return target.files!![0];
        } else {
            //For input tags
            return target.value;
        }
    }


    getPayload = (): any => {
        const payload = {
            'siteId': this.props.siteId,
            'email': this.state.email,
            'name': this.state.name,
            'mobile':this.state.mobile,
            'subject':this.state.subject,
            'message':this.state.message,
        }
        return payload;
    }


    contactUser = () => {
        this.setState({processing: true});
        axiosClient.post(`/listingfy-service/website/`+this.props.siteId+ '/contact-us', this.getPayload()).then((res) => {
            if (res.status === 200) {
                this.setState(() => ({usermessage: {message: "Message Sent Successfully", type: "success"}}));
                this.setState({processing: false, messageSent: true});
            } else {
                this.setState({usermessage: {message: "Unknown error sending message: " + res.status, type: "error"}});
                this.setState({processing: false, messageSent: false});
            }
        }).catch((error: any) => {
            console.log("#94 Contact Us Layout 2:", error);
            this.setState(() => ({usermessage: {message: "Error occured", type: "error"}}))
            this.setState({processing: false, messageSent: false});
        });
    }

    submitContactForm = (event: FormEvent)=> {
        event.preventDefault();
        this.contactUser();
    }

    render(){
        return <section id={this.props.htmlId} className={`${this.props.themeClass} contactUs_l2 ptb_50`}>
            <div className="container">
                <div className="triangle"></div>
                <ScrollAnimation animateIn='fadeInUp'>
                <div className="row justify-content-center mb-3">
                    <HeadingTitles heading={this.props.data['title']} classesObject={{headingClass:'heading-style mb-0', descriptionClass: 'sub-title text-muted'}} layout="layout_1" headingTag="h2" description={this.props.data['subtitle']} alignment="center"/>
                </div>
                </ScrollAnimation>
                <div className="row">
                    <div className="col-md-6">
                        <ScrollAnimation animateIn='fadeInLeft'>

                        <div className="contact-box">
                            {this.state.messageSent ?
                                <div id="contact-form-box" className={"alert alert-success"}>Query submitted successfully</div> :
                                <form id="contact-form" onSubmit={this.submitContactForm} role="form">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="nameInput" className="form-label sub-heading">Name</label>
                                                <div className="form-group">
                                                    <input type="text" className="form-control title_description"
                                                           name={"name"}
                                                           placeholder="Enter your name"
                                                           onChange={event => this.handleChange(event)} required/>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-6">
                                                    <label htmlFor="nameInput" className="form-label sub-heading">Email</label>
                                                    <div className="form-group">
                                                        <input type="email" className="form-control title_description"
                                                               name={"email"}
                                                               placeholder="Enter your email"
                                                               onChange={event => this.handleChange(event)}
                                                               />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="nameInput" className="form-label sub-heading">Mobile number</label>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control title_description"
                                                               name={"mobile"}
                                                               placeholder="Enter your mobile number" maxLength={10} pattern={"[0-9]*"}
                                                               onChange={event => this.handleChange(event)}
                                                               required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                <label htmlFor="nameInput" className="form-label sub-heading">Subject</label>
                                                <div className="form-group">
                                                    <input type="text" className="form-control title_description"
                                                           name={"subject"}
                                                           placeholder="Enter your subject"
                                                           onChange={event => this.handleChange(event)}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-4">
                                            <label htmlFor="nameInput" className="form-label sub-heading">Message</label>
                                            <div className="form-group">
                                                    <textarea className="form-control title_description"
                                                              name={"message"}
                                                              placeholder="Enter your message"
                                                              onChange={event => this.handleChange(event)}
                                                              required/>
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <LoaderButton loading={this.state.processing} label={this.props.data['action'] ? this.props.data['action']: "Submit query"} cssName={"btn btn-dark theme_btn w-100"}/>
                                            {
                                                this.state.usermessage?.type === 'error' ?
                                                <div
                                                    className={"alert alert-danger"}>{this.state.usermessage?.message}</div> : null
                                            }
                                        </div>
                                    </div>
                                </form>}
                        </div>
                        </ScrollAnimation>
                    </div>
                    {this.props.data['googleMapEmbedLink'] ?
                        <>
                        <div className="col-md-6">
                            <ScrollAnimation animateIn='fadeInRight'>
                                <div className="overflow-auto" dangerouslySetInnerHTML={{__html: this.props.data['googleMapEmbedLink'] || ''}}></div>
                            </ScrollAnimation>
                        </div>
                        <div className="row mt-5">
                            { this.props.data['location']?
                            <div className="col-md-4 col-sm-12 mb-sm-3">
                                <ScrollAnimation animateIn='fadeInDown'>

                                <div className="card bg-shadow border-0">
                                    <div className="card-body">
                                        <div className="contact-info-wrapper d-flex">
                                            <div className="icon_wrapper theme_icons">
                                                <FontAwesomeIcon icon={home} className="lock-icon icons"/>
                                            </div>
                                            <div className="info_wrap ms-4">
                                                <h4 className="sub-title">Address</h4>
                                                <span className="media-body align-self-center heading-style">{this.props.data['location']}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </ScrollAnimation>
                            </div>:''}

                            { this.props.data['phone']?
                                <div className="col-md-4 col-sm-12 mb-sm-3">
                                    <ScrollAnimation animateIn='fadeInDown'>
                                <div className="card bg-shadow border-0">
                                    <div className="card-body">
                                        <div className="contact-info-wrapper d-flex">
                                            <div className="icon_wrapper theme_icons">
                                                <a href={`tel:${this.props.data['phone']}`} className='text-white'>
                                                    <FontAwesomeIcon icon={phone} className="lock-icon icons"/></a>
                                            </div>
                                            <div className="info_wrap ms-4">
                                                <h4 className="sub-title">Contact</h4>
                                                <span className="media-body align-self-center heading-style">{this.props.data['phone']}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </ScrollAnimation>
                            </div> :''}

                            { this.props.data['whatsapp']?
                                <div className="col-md-4 mb-4 col-sm-12 mb-sm-3">
                                    <ScrollAnimation animateIn='fadeInDown'>
                                <div className="card bg-shadow border-0">
                                    <div className="card-body">
                                        <div className="contact-info-wrapper d-flex">
                                            <div className="icon_wrapper theme_icons">
                                                <a href={`https://wa.me/91${this.props.data['whatsapp']}?text=I'm%20inquiring%20about%20the%20website%20building`} target={"_blank"} className='text-white'>
                                                    <FontAwesomeIcon icon={faWhatsapp as IconProp} className="lock-icon icons"/></a>
                                            </div>
                                            <div className="info_wrap ms-4">
                                                <h4 className="sub-title">Whatsapp</h4>
                                                <span className="media-body align-self-center heading-style">{this.props.data['whatsapp']}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </ScrollAnimation>
                            </div> :''}

                            { this.props.data['email']?

                                <div className="col">
                                    <ScrollAnimation animateIn='fadeInDown'>
                                <div className="card bg-shadow border-0">
                                    <div className="card-body">
                                        <div className="contact-info-wrapper d-flex">
                                            <div className="icon_wrapper theme_icons">
                                                <a href={`mailto:${this.props.data['email']}`} className='text-white'>

                                                    <FontAwesomeIcon icon={message} className="lock-icon icons "/></a>
                                            </div>
                                            <div className="info_wrap ms-4">
                                                <h4 className="sub-title">Email</h4>
                                                <span className="media-body align-self-center heading-style">{this.props.data['email']}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    </ScrollAnimation>
                            </div>:''}
                        </div>
                            </>

                        :
                        <div className="col-md-6">
                            {/*<ScrollAnimation animateIn='fadeInRight'>*/}

                            { this.props.data['location']?
                            <div className="col mb-4">
                            <div className="card bg-shadow border-0 bg_1">
                                <div className="card-body">
                                    <div className="contact-info-wrapper d-flex">
                                        <div className="icon_wrapper theme_icons">
                                            <FontAwesomeIcon icon={home} className="lock-icon icons"/>
                                        </div>
                                        <div className="info_wrap ms-4">
                                            <h4 className="sub-title">Address</h4>
                                            <span className="media-body align-self-center heading-style">{this.props.data['location']}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            </div>:''}

                            { this.props.data['phone']?
                                <div className="col mb-4">
                            <div className="card bg-shadow border-0 bg_2">
                                <div className="card-body">
                                    <div className="contact-info-wrapper d-flex">
                                        <div className="icon_wrapper theme_icons">
                                            <a href={`tel:${this.props.data['phone']}`} className='text-white'>
                                                <FontAwesomeIcon icon={phone} className="lock-icon icons"/></a>
                                        </div>
                                        <div className="info_wrap ms-4">
                                            <h4 className="sub-title">Contact</h4>
                                            <span className="media-body align-self-center heading-style">{this.props.data['phone']}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                </div> :''}

                            { this.props.data['whatsapp']?
                                <div className="col mb-4">
                                <div className="card bg-shadow border-0 bg_3">
                                    <div className="card-body">
                                        <div className="contact-info-wrapper d-flex">
                                            <div className="icon_wrapper theme_icons">
                                                <a href={`https://wa.me/91${this.props.data['whatsapp']}?text=I'm%20inquiring%20about%20the%20website%20building`} target={"_blank"} className='text-white'>
                                                    <FontAwesomeIcon icon={faWhatsapp as IconProp} className="lock-icon icons"/></a>
                                            </div>
                                            <div className="info_wrap ms-4">
                                                <h4 className="sub-title">Whatsapp</h4>
                                                <span className="media-body align-self-center heading-style">{this.props.data['whatsapp']}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>: ''}

                            { this.props.data['email']?
                                <div className="col mb-4">
                                <div className="card bg-shadow border-0 bg_4">
                                    <div className="card-body">
                                        <div className="contact-info-wrapper d-flex">
                                            <div className="icon_wrapper theme_icons">
                                                <a href={`mailto:${this.props.data['email']}`} className='text-white'>

                                                    <FontAwesomeIcon icon={message} className="lock-icon icons "/></a>
                                            </div>
                                            <div className="info_wrap ms-4">
                                                <h4 className="sub-title">Email</h4>
                                                <span className="media-body align-self-center heading-style">{this.props.data['email']}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>: ''}
                            {/*</ScrollAnimation>*/}
                        </div>
                    }

                </div>
            </div>
        </section>;
    }
}

export default ContactUsLayout2;
