import React from "react";
import {Helmet} from "react-helmet";
import moment from "moment/moment";
import "./BlogDetail.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF as facebook,faTwitter as twitter,faWhatsapp as whatsapp} from "@fortawesome/free-brands-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import LazyLoad from "react-lazyload";

export const BlogDetail = (props: { config: any, htmlId?: string, themeClass?:string }) => {

    return <section id={props?.htmlId}  className={`${props.themeClass}blog_details ptb_80 position-relative testingFontFamily`}>
        <Helmet link={[{
            rel: 'icon',
            type: 'image/png',
            href: ""
        }]}>
            <title>{props?.config?.title ?? 'Please Title not set!!!'}</title>
            <meta name="description" content={props?.config?.description ?? 'Description not set'}/>
            <meta property="og:title" content={props?.config?.title ?? 'og:title not defined'}/>
            <meta property="og:description" content={props?.config?.description ?? 'og description not defined'}/>
            <meta property="og:image" content={props?.config?.image?.url ?? 'url not defined'}/>
            <meta property="og:type" content={"Blog" ?? 'website'}/>
            <meta property="og:url" content={`/blog/${props.config?.slug}` ?? 'url not set'}/>
        </Helmet>
        <div className="container">
            <div className="blog_details_top_heading">
                <h1 className="heading-title heading-style">{props.config?.title}</h1>
                <h6 style={{color: "grey"}} className="sub-title">By carvia tech/ Blogs / {moment(props.config?.createdDate).format("MMMM DD, YYYY")}</h6>
            </div>
            <div className="Blog_img">
                <LazyLoad offset={100}>
                <img className="card-img" loading="lazy" alt={""}
                     src={props.config?.image?.url ?? "https://blog.hubspot.com/hubfs/what-is-a-blog-2.jpg"}/>
                </LazyLoad>
            </div>
                <div className="blog_content sub-title" dangerouslySetInnerHTML={{__html: props.config?.contentMarkup}}></div>
            <div className="blog_social_share_btn  d-flex justify-content-center mt-5">
                <a className="social-share facebook" href={"/#"}>
                    <span><FontAwesomeIcon icon={facebook as IconProp}
                                           className="lock-icon icons me-2"/>
                    </span>
                    <span className="after-text">
                        Share on facebook
                    </span>
                </a>
                <a className="social-share twitter" href={"/#"}>
                    <span><FontAwesomeIcon icon={twitter  as IconProp}
                                           className="lock-icon icons me-2"/>
                    </span>
                    <span className="after-text">
                        Share on Twitter
                    </span>
                </a>
                <a className="social-share wattsapp" href={"/#"}>
                    <span><FontAwesomeIcon icon={whatsapp  as IconProp}
                                           className="lock-icon icons me-2"/>
                    </span>
                    <span className="after-text">
                        Share on WhatsApp
                    </span>
                </a>
            </div>
        </div>
    </section>
}
