import React, {Component} from "react";
import {BannerProps} from "../../../../models/BannerModel";
import './BannerLayout4.scss'
import {Carousel} from "react-bootstrap";

class BannerLayout4 extends Component<BannerProps, any> {
    slidesData = [];

    constructor(props: any) {
        super(props);

        this.setItems();
        this.state = {
            name: '',
            description: '',
        };
    }

    setItems = () => {
        let slides: any = [];
        this.props.data?.slides.map((slide: any) => {
            if (slide.active) {
                slides.push(slide);
            }
        });
        this.slidesData = slides;
    }

    render() {
        return <section id={this.props.htmlId} className={`${this.props.themeClass} banner-sec_l4`}>
            <Carousel indicators={false} controls={this.slidesData?.length > 1}>
                {this.slidesData?.map((slide: any, index: any) =>
                    <Carousel.Item key={index}
                                   interval={(this.slidesData?.length === 1) ? null : this.props.data?.slideDelay}>
                        <div className="mainbannerwrap" style={{
                            backgroundImage: ` url(${slide['url']})`,
                        }}>
                            <div className={` ${this.props.data.showGridEffect ? 'banner__grid' : 'banner__overlay'}`}
                                 style={{
                                     backgroundColor: `rgba(0,0,0,${(this.props.data?.overlayOpacity / 10) ?? 0.7})`,
                                 }}></div>
                            <div className="container">
                                <div className="row">
                                    <div className="banner-content animate fadeInUp three text-center">
                                        <div className={'banner-slide-contact d-flex flex-wrap'}>
                                            <div className={'title'}>
                                                <h1 className="heading-style">{slide['title']}</h1>
                                            </div>
                                            <div className={'contact-box'}>
                                                <p>Call us now on <a href={`tel:${this.props.data['contact']}`}>+91 {this.props.data['contact']}</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    )}
            </Carousel>
        </section>
    }
    }
    export default BannerLayout4;