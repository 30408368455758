import moment from "moment";
import React from "react";
import "./Blog.scss";
import {PageRouteBuilder} from "../../../../util/PageRouteBuilder";
import {Link} from "react-router-dom";
import {useLocation, useParams} from "react-router";
import LazyLoad from "react-lazyload";

interface BlogProps {
    id: string
    title: string,
    description: string,
    image: { cid: string, url: string },
    createdDate: number,
    slug?: string,
}

export const BlogLayout = (props: BlogProps) => {
    const location = useLocation();
    const params = useParams<{ blogId: string, id?: string, 0: string }>();
    return (<div className="col-md-4 mb-sm-4">
            <div className="blog-wrapper">
                <div className="blog_header zoom-effect-2 ">
                    <Link to={PageRouteBuilder.buildPageRoute(location?.pathname, `blog/${props?.slug}`, params?.id)}>
                        <LazyLoad offset={100}>
                        <img id="img-fluid d-block blog-image" height={250} alt={""}
                             src={props.image?.url ?? "https://cdn.pixabay.com/photo/2018/08/14/13/23/ocean-3605547__340.jpg"}
                             loading="lazy"></img>
                        </LazyLoad>
                    </Link>
                    <div className="blog_date fw-bold">
                            <span>
                                {moment(props.createdDate).format("MMM DD")}
                            </span>
                    </div>
                </div>
                <div className="blog_summary">
                    <Link to={PageRouteBuilder.buildPageRoute(location.pathname, `blog/${props.slug}`, params.id)}
                          className="text-black">
                        <h4 className="blog_title title_description">
                            {props.title ?? "BlogLayout Title"}
                        </h4>
                    </Link>
                    <div className="blog_content sub-title">
                        <p>{props.description.length < 60 ? props.description : props.description.substring(0, 120)}</p>
                    </div>
                    <Link to={PageRouteBuilder.buildPageRoute(location.pathname, `blog/${props.slug}`, params.id)}
                          className="blog-button title_description">
                        Read more
                        <span className="blog-svg-icon">
                                <svg aria-hidden="true" role="img" focusable="false" xmlns="http://www.w3.org/2000/svg"
                                     width="24" height="24" viewBox="0 0 28 28" fill="none" stroke="currentColor"
                                     stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <line x1="0" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 5 19 12 12 19"></polyline>
                                </svg>
                            </span>
                    </Link>
                </div>
            </div>
        </div>
    )
}
