import React, {Component, FormEvent, RefObject} from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {ApplicationFormProps} from "../../../../models/BannerModel";
import './ApplicationForm.scss';
import {Form} from "react-bootstrap";
import axiosClient from "../../../../config/AxiosRestClient";
import LoaderButton from "../../../../components/LoaderButton";
import toast from "react-hot-toast";

interface ApplicationFormBscState {
    usermessage?: UserMessage,
    processing: boolean,
    messageSent: boolean,
    studentName: string,
    fatherName: string,
    passOutClass: string,
    schoolName: string,
    address: string,
    city: string,
    state: string,
    pincode: string,
    nationality: string,
    emailId: string,
    dateOfBirth: string,
    mobile: any,
    alternateNumber: any,
    whatsappNumber: any,
    category: any,
    stream: any,
    cid: string,
    previewImage: any
}

interface UserMessage {
    message: string
    type: string
}

class ApplicationForm extends Component<ApplicationFormProps, ApplicationFormBscState> {

    form: RefObject<HTMLFormElement>

    constructor(props: ApplicationFormProps) {
        super(props);
        this.state = {
            processing: false,
            messageSent: false,
            studentName: '',
            fatherName: '',
            passOutClass: '',
            schoolName: '',
            address: '',
            city: '',
            state: '',
            pincode: '',
            nationality: '',
            emailId: '',
            dateOfBirth: '',
            mobile: '',
            alternateNumber: '',
            whatsappNumber: '',
            category: '',
            stream: '',
            cid: '',
            previewImage: null
        }
        console.log('#48', this.state)
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.imageUpload = this.imageUpload.bind(this);
    }

    handleChange(event: any) {
        const target = event.target;
        const name = target.name;
        const value = this.extractEventValue(target);
        if (name === 'cid' && target.files && target.files[0]) {
            const file = target.files[0];
            this.imageUpload(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                this.setState({previewImage: e.target?.result as string});
            };
            reader.readAsDataURL(target.files[0]);
        }

        this.setState({
            ...this.state,
            [name]: value
        });
    }

    private extractEventValue(target: any) {
        if (target.type === 'checkbox') {
            return target.checked;
        } else if (target.type === 'file') {
            return target.files!![0];
        } else {
            return target.value;
        }
    }

    getPayload = (): any => {
        const payload = {
            // 'siteId': this.props.siteId,
            'emailId': this.state.emailId,
            'studentName': this.state.studentName,
            'mobile': this.state.mobile,
            'fatherName': this.state.fatherName,
            'passOutClass': this.state.passOutClass,
            'schoolName': this.state.schoolName,
            'address': this.state.address,
            'city': this.state.city,
            'state': this.state.state,
            'pincode': this.state.pincode,
            'nationality': this.state.nationality,
            'dateOfBirth': this.state.dateOfBirth,
            'alternateNumber': this.state.alternateNumber,
            'whatsappNumber': this.state.whatsappNumber,
            'category': this.state.category,
            'stream': this.state.stream,
            'cid': this.state.cid
        }
        return payload;
    }

    imageUpload = (file?: File) => {
        this.setState({processing: true});

        if (!file) {
            console.error("No file selected");
            this.setState({
                processing: false,
                usermessage: {message: "No file selected", type: "error"}
            });
            return;
        }

        // Create FormData with the image
        let formData = new FormData();
        formData.append('file', file);

        // Upload the image
        axiosClient.post(`/listingfy-service/upload`, formData).then((res) => {
            if (res.status === 200) {
                const cid = res.data.id;
                toast.success('Image Uploaded !');
                this.setState({
                    cid: cid,
                    usermessage: {message: "Form Submit Successfully", type: "success"},
                    processing: false,
                    messageSent: false
                });

            } else {
                toast.error('Unknown error !')
                this.setState({
                    usermessage: {message: "Unknown error sending message: " + res.status, type: "error"},
                    processing: false,
                    messageSent: false
                });
            }
        }).catch((error) => {
            toast.error('Image size is too large');
            console.log("#90 Application Form:", error);
            this.setState({
                usermessage: {message: "Error occurred", type: "error"},
                processing: false,
                messageSent: false
            });
        });
    }


    applicantUser = () => {
        this.setState({processing: true});
        axiosClient.post(`/listingfy-service` + '/application-form', this.getPayload()).then((res) => {
            if (res.status === 200) {
                toast.success('Form Submit successfully');
                this.setState({processing: false, messageSent: true});
            } else {
                this.setState({usermessage: {message: "Unknown error sending application form: " + res.status, type: "error"}});
                this.setState({processing: false, messageSent: false});
            }
        }).catch((error: any) => {
            console.log("#90 Application Form:", error);
            toast.error('File submission error');
            this.setState({processing: false, messageSent: false});
        });
    }
    submitApplicantForm = (event: FormEvent) => {
        event.preventDefault();
        this.applicantUser();
    }


    render() {
        return <section id={this.props.htmlId}
                        className={`${this.props.themeClass} applicationForm ptb_80 testingFontFamily`}>
            <div className="container">
                <div className="row justify-content-center mb-5">
                    <ScrollAnimation animateIn='fadeInUp'>
                        <div className="section-heading text-center">
                            <h4>NMC Application Form</h4>
                        </div>
                    </ScrollAnimation>
                </div>

                <div className="contact-box text-center">
                    <form id="contact-form" role="form" onSubmit={this.submitApplicantForm}>
                        <div className="row">
                            <div className={'col-md-4'}>
                                <div className="avatar-upload">
                                    <div className="avatar-edit">
                                        <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg"
                                               onChange={this.handleChange} name="cid"/>
                                        <label htmlFor="imageUpload"><i className="bi bi-upload"></i></label>
                                    </div>
                                    <div className="avatar-preview">
                                        {this.state.previewImage ?
                                            <img src={this.state.previewImage} alt="Preview"/>
                                            :
                                            <div id="imagePreview">
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-8'}>

                                <div className="user__details">
                                    <div className="input__box">
                                        <span className="details">Student Name<span className={'text-danger'}> *</span></span>
                                        <input type="text" placeholder="E.g: John Smith" name={"studentName"}
                                               onChange={event => this.handleChange(event)} required/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">Father Name</span>
                                        <input type="text" placeholder="E.g: John Smith" name={"fatherName"}
                                               onChange={event => this.handleChange(event)}/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">Email</span>
                                        <input type="email" placeholder="johnsmith@hotmail.com" name={"emailId"}
                                               onChange={event => this.handleChange(event)}/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">Pass out Class</span>
                                        <input type="text" placeholder="E.g: 10th" name={"passOutClass"}
                                               onChange={event => this.handleChange(event)}/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">School Name<span
                                            className={'text-danger'}> *</span></span>
                                        <input type="text" placeholder="School Name" name={"schoolName"}
                                               onChange={event => this.handleChange(event)} required/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">Date of Birth<span className={'text-danger'}> *</span></span>
                                        <input type="date" placeholder="02/06/2011" name={"dateOfBirth"}
                                               onChange={event => this.handleChange(event)} required/>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <div className="user__details">
                                    <div className="input__box">
                                        <span className="details">Phone Number<span className={'text-danger'}> *</span></span>
                                        <input type="tel"
                                               placeholder="98*** ***18" name={"mobile"}
                                               onChange={event => this.handleChange(event)} required/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">Alternate Number</span>
                                        <input type="tel"
                                               placeholder="98*** ***15" name={"alternateNumber"}
                                               onChange={event => this.handleChange(event)}/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">Whatsapp Number</span>
                                        <input type="tel"
                                               placeholder="98*** ***10" name={"whatsappNumber"}
                                               onChange={event => this.handleChange(event)}/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">Address</span>
                                        <input type="text" placeholder="E.g: Near Balaji Mandir" name={"address"}
                                               onChange={event => this.handleChange(event)}/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">City</span>
                                        <input type="text" placeholder="E.g: Agra " name={"city"}
                                               onChange={event => this.handleChange(event)}/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">State</span>
                                        <input type="text" placeholder="E.g: U.P" name={"state"}
                                               onChange={event => this.handleChange(event)}/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">Pincode</span>
                                        <input type="text" placeholder="E.g: 282010" name={"pincode"}
                                               onChange={event => this.handleChange(event)}/>
                                    </div>
                                    <div className="input__box">
                                        <span className="details">Nationality</span>
                                        <input type="text" placeholder="E.g: India" name={"nationality"}
                                               onChange={event => this.handleChange(event)}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className={'border-bottom'}>
                                        <span className="details">Category<span
                                            className={'text-danger'}> *</span></span>
                                        <div className={'d-flex justify-content-between mt-3 mb-3'}>
                                            <Form.Check
                                                className="me-4"
                                                type="radio"
                                                id="category-radio-1"
                                                label="SC"
                                                name={"category"}
                                                value={'SC'}
                                                required
                                                onChange={event => this.handleChange(event)}
                                            />
                                            <Form.Check
                                                className="me-4"
                                                type="radio"
                                                id="category-radio-2"
                                                label="ST"
                                                value={'ST'}
                                                name={"category"}
                                                required
                                                onChange={event => this.handleChange(event)}
                                            />
                                            <Form.Check
                                                className="me-4"
                                                type="radio"
                                                id="category-radio-3"
                                                label="O.B.C"
                                                value={'OBC'}
                                                name={"category"}
                                                required
                                                onChange={event => this.handleChange(event)}
                                            />
                                            <Form.Check
                                                className="me-4"
                                                type="radio"
                                                id="category-radio-4"
                                                label="General"
                                                name={"category"}
                                                value={'GENERAL'}
                                                required
                                                onChange={event => this.handleChange(event)}
                                            />
                                        </div>
                                    </div>
                                    <div className={'mt-3'}>
                                        <span className="details">Stream<span
                                            className={'text-danger'}> *</span></span>
                                        <div className={'d-flex justify-content-between mt-3'}>
                                            <Form.Check
                                                className="me-4"
                                                type="radio"
                                                id="stream-radio-1"
                                                name={"stream"}
                                                label="MEDICAL"
                                                value={'MEDICAL'}
                                                required
                                                onChange={event => this.handleChange(event)}
                                            />
                                            <Form.Check
                                                className="me-4"
                                                type="radio"
                                                id="stream-radio-2"
                                                name={"stream"}
                                                required
                                                label="ENGINEERING"
                                                value={'ENGINEERING'}
                                                onChange={event => this.handleChange(event)}
                                            />
                                            <Form.Check
                                                className="me-4"
                                                type="radio"
                                                id="stream-radio-3"
                                                required
                                                name={"stream"}
                                                label="CLAT"
                                                value={'CLAT'}
                                                onChange={event => this.handleChange(event)}
                                            />
                                            <Form.Check
                                                className="me-4"
                                                type="radio"
                                                id="stream-radio-4"
                                                name={"stream"}
                                                required
                                                label="CPT"
                                                value={'CPT'}
                                                onChange={event => this.handleChange(event)}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div
                                className="form-group col-md-12 d-flex justify-content-between align-items-center mt-5">
                                <div className={'note text-start'}>
                                    <b> NOTE :</b>

                                    <br/>
                                    <b> 1. Fields marked with an asterisk (<span className={'text-danger'}> * </span>)
                                        are
                                        required.
                                    </b>
                                    <br/>
                                    <b> 2. Accepted image formats (.png, .jpg, .jpeg).</b>
                                    <br/>
                                    <b> 3. Please upload images less than 5 MB.</b>
                                </div>
                                <LoaderButton loading={this.state.processing}
                                              label={'Submit Form'}
                                              cssName={"btn theme_btn"}/>
                            </div>
                        </div>

                    </form>
                </div>

            </div>
        </section>;
    }
}

export default ApplicationForm