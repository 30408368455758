import {Component} from "react";
import "./AppFooter.scss";
import {faTwitter, faYoutube, faLinkedinIn, faInstagramSquare, faFacebookF} from "@fortawesome/free-brands-svg-icons";
import {FooterProps} from "../../../../models/ContactUsModel";
import SocialIcon from "../../../../components/SocialIcon";

class Footer extends Component<FooterProps, any> {

    componentDidMount() {
    }

    render() {
        return (
            <footer id={this.props.htmlId} className={`${this.props.themeClass} footer_layout_1 ptb_80 pb-3 testingFontFamily`}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-5">
                            <div className="footer_layout__1__wrapper">
                                <div className="footer_layout_1__logo">
                                    <h2 className="heading-style mb-2">{this.props.data['title']}</h2>
                                </div>
                                <p className="mini-title">{this.props.data['description']}</p>
                            </div>
                        </div>
                        <div className="col-md-7 mt-xs-24 text-md-end left-content">
                            <div className="footer_layout_1__social-icon">
                                <h3 className="heading-style">Social Links</h3>
                                <SocialIcon url={this.props.data['facebookProfile']} icon={faFacebookF}/>
                                <SocialIcon url={this.props.data['twitterProfile']} icon={faTwitter}/>
                                <SocialIcon url={this.props.data['youtubeProfile']} icon={faYoutube}/>
                                <SocialIcon url={this.props.data['linkedInProfile']} icon={faLinkedinIn}/>
                                <SocialIcon url={this.props.data['instagramProfile']} icon={faInstagramSquare}/>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="bottom-footer pb-1  d-flex justify-content-center align-items-center">
                        <div className="row">
                            <div className="col">
                                <div className="footer-copyright">
                                    <p>
                                        Copyright © 2022 <b className="company_name">{this.props.data.companyName}</b>. All Rights Reserved.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        );
    }
}

export default Footer;

