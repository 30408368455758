import './NotFound.scss';
import history from "../../util/History";
import not_found from "../../assets/css/images/not-found.png";
import React from "react";

export const NotFound = () => {
    return (
        <div className="ptb_50 not_found">
                <div className="container">
                    <div className="row justify-content-center text-center">
                            <img src={not_found} className="w-25 mb-3" loading="lazy" alt="not found"/>
                            <h2>Oops! That Page Can’t Be Found.</h2>
                            <p>The page you are looking for no longer exists. Perhaps you can return back to the site’s homepage</p>
                            <div className="col-6">
                                <button className="btn btn-success" onClick={(e) => {history.goBack()}}>Home page</button>
                            </div>
                    </div>
                </div>
        </div>
    )
}
