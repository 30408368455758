import React from "react";

export const NoSuchWidget = () => {

    return  <div className='align-content-center' style={{height: `20rem`, backgroundColor: "#d4ffc2"}}>
        <div className="heading-content">
            <div className="mb-4">
                <div  className='align-content-center' style={{background: "#d4ffc2"}}>
                    <h3 className="align-items-center">No relevant widget found</h3>
                    <p>Kindly Add the widget in the page (In Page Editor Bucket)</p>
                </div>
            </div>
        </div>
    </div>





}

