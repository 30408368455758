import {useEffect, useState} from "react";
import axiosClient from "../config/AxiosRestClient";
import {WidgetRenderer} from "../themes/bsc/widgets/WidgetRenderer";
import {Helmet} from "react-helmet";
import {useLocation, useParams} from "react-router";
import ServerError from "../components/serverError/ServerError";
import ThemeManager from "../themes/ThemeManager";
import {NotFound} from "../screens/404 page/NotFound";
import "./../index.css";

export const TenantHome = (props: any) => {
    const [details, setDetails] = useState<any | undefined>(props.staticContext?.data ?? undefined);
    const [siteId, setSiteId] = useState<any>('');
    const [themeSlug, setThemeSlug] = useState<any>('');
    const [breadCrumbs, setBreadcrumbs] = useState<any>([]);
    const params: { id?: string, 0?: string } = useParams();
    let [statusCode, setStatusCode] = useState<any>(0);
    let [error, setError] = useState<string>('');
    const [theme, setTheme] = useState("");
    const location = useLocation();

    useEffect(() => {
        if (!props.staticContext?.data)
            loadSite(params[0]?.split("/") ?? []);
        window.scroll(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.staticContext, params[0]])

    const loadSite = (paths: string[]) => {
        fetchWebsiteDetails(params.id, paths ? paths[0] : '', paths && paths.length > 1 ? paths[1] : undefined).then(res => {
            setEnqueueFontFamily(res?.theme?.settings?.theme?.fontFamily);
            ThemeManager.setThemeSlug(res?.theme?.slug ?? '');
            setThemeSlug(res?.theme?.slug ?? '');
            switchTheme(ThemeManager.getCurrentThemeSlug());
            setBreadcrumbs(res?.breadcrumbs);
            setSiteId(res?.website?.id);
            setThemeColors(res?.theme?.settings?.theme?.colors?.primary ?? '', res?.theme?.settings?.theme?.colors?.secondary ?? '', res?.theme?.settings?.theme?.colors?.tertiary ?? '');
            setDetails(res);
        }).catch((err: any) => {
            console.log("#38 Tenant Home:", err);
        });
    }

    const setEnqueueFontFamily = (fontFamilySettings: any) => {
        for (let key in fontFamilySettings) {
            if (fontFamilySettings.hasOwnProperty(key)) {
                const link = document.createElement('link');
                const rootVariable = document.documentElement;
                link.rel = 'stylesheet';
                link.href = fontFamilySettings[key]?.url;
                document.head.appendChild(link);
                rootVariable.style.setProperty('--' + key + 'FontFamily', fontFamilySettings[key]?.name);
            }
        }
    }

    const setThemeColors = (primary: string, secondary: string, tertiary: string) => {
        const rootVariable = document.documentElement;

        const _primary = document.createElement('link');
        _primary.rel = 'stylesheet';
        _primary.href = primary;
        document.head.appendChild(_primary);
        rootVariable.style.setProperty('--primaryColor', primary);

        const _secondary = document.createElement('link');
        _secondary.rel = 'stylesheet';
        _secondary.href = primary;
        document.head.appendChild(_secondary);
        rootVariable.style.setProperty('--secondaryColor', secondary);

        const _tertiary = document.createElement('link');
        _tertiary.rel = 'stylesheet';
        _tertiary.href = primary;
        document.head.appendChild(_tertiary);
        rootVariable.style.setProperty('--tertiaryColor', tertiary);
        // if (primary !== '') {
        //     style.appendChild(document.createTextNode(`
        //     h1, h2, h3, h4, h5, h6 {
        //         color: ${primary}
        //     }
        // `));
        // }
        // if (secondary !== '') {
        //     style.appendChild(document.createTextNode(`
        //     span, p{
        //         color: ${secondary}
        //     }
        // `));
        // }
        // if (tertiary !== '') {
        //     style.appendChild(document.createTextNode(`
        //      i{
        //         color: ${tertiary}
        //     }
        // `));
        // }
        // document.head.appendChild(style);

    }

    const switchTheme = (themeSlug: string): void => {
        if (themeSlug) {
            document.body.classList.add(themeSlug);
            switch (themeSlug) {
                case 'retro_theme':
                    require('../themes/retro_theme/retro_theme.scss');
                    setTheme("retro_theme")
                    break;
                case 'ocean_theme':
                    require('../themes/ocean_theme/ocean_theme.scss');
                    setTheme("ocean_theme")
                    break;
                case 'splashy_theme':
                    require('../themes/splashy_theme/splashy_theme.scss');
                    setTheme("splashy_theme")
                    break;
                case 'elegant_theme':
                    require('../../src/themes/elegant_theme/elegant_theme.scss');
                    setTheme("elegant_theme")
                    break;
                case 'autumn_theme':
                    require('../../src/themes/autumn_theme/autumn_theme.scss');
                    setTheme("autumn_theme")
                    break;
                case 'classic_theme':
                    require('../../src/themes/classic_theme/classic_theme.scss');
                    setTheme("classic_theme")
                    break;
                case 'indigo_theme':
                    require('../../src/themes/indigo_theme/indigo_theme.scss');
                    setTheme("indigo_theme")
                    break;
                case 'cherry_theme':
                    require('../../src/themes/cherry_theme/cherry_theme.scss');
                    setTheme("cherry_theme")
                    break;
                case 'environmental_theme':
                    require('../../src/themes/environmental_theme/environmental_theme.scss');
                    setTheme("environmental_theme")
                    break;
                default:
                    require('../../src/themes/bsc/bsc.scss');
            }
        } else {
            require('../../src/themes/bsc/bsc.scss');
        }
    }

    const fetchWebsiteDetails = (siteId: string | undefined, path?: string, contentId?: string) => {
        const pagePath = path ? path.indexOf("#") !== -1 ? path.substring(0, path.indexOf('#')) : path : '';
        return new Promise<any>((resolve, reject) => {
            axiosClient.get(`/listingfy-service/website/template`, {
                params: {'siteId': siteId, page: pagePath}
            }).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                console.log("#105 Tenant Home:", err);
                setStatusCode(err.response.status);
                setError(err.response.data.error);
                reject(err);
            });
        });
    }

    const getWidgetClasses = (shadeClass: string | undefined, widgetClass: string) => {
        if (widgetClass) {
            return ((shadeClass) ? widgetClass + ' __' + shadeClass : widgetClass).toLowerCase();
        } else {
            return '';
        }
    }

    const displayView = () => {
        if (statusCode === 400) {
            return <NotFound/>
        } else if (statusCode === 500) {
            return <ServerError statusCode={statusCode} error={error}/>
        } else {
            return <div className={theme}><Helmet link={[{
                rel: 'icon',
                type: 'image/png',
                href: details?.website.favicon
            }]}>
                <title>{details?.seoMeta?.ogTitle ?? 'Please Title not set!!!'}</title>
                <meta name="description" content={details?.seoMeta?.ogDescription ?? 'Description not set'}/>
                <meta property="og:title" content={details?.seoMeta?.ogTitle ?? 'og:title not defined'}/>
                <meta property="og:description"
                      content={details?.seoMeta?.ogDescription ?? 'og description not defined'}/>
                <meta property="og:image" content={details?.seoMeta?.ogImage?.url ?? 'url not defined'}/>
                <meta property="og:type" content={details?.seoMeta?.ogType ?? 'website'}/>
                <meta property="og:url" content={details?.seoMeta?.ogUrl ?? 'url not set'}/>
            </Helmet>
                {
                    (details ? details['widgets'] : []).filter((widget: any) => widget['status'] === 'Published').map((widget: any) => (
                        <div key={widget?.id} id={widget['id'] ?? ""}
                             className={`${(themeSlug) ? 'widget_theme_section' : ''} section-wrapper position-relative`}>
                            {WidgetRenderer.renderV2(siteId, breadCrumbs, widget, props, {}, {}, getWidgetClasses(widget['config']?.['shade'], widget['config']?.['widgetClass']), widget['config']?.['themeShade']?.['shadeClass'])}
                        </div>
                    ))
                }
            </div>
        }
    }

    return displayView()
}


