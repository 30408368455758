import React, {Component, RefObject} from "react";
import "./AboutUs.scss";
import {AboutUsProps} from "../../../../models/BannerModel";
import {MediaSelector} from "../MediaSelector/MediaSelector";
import {UniversalReadMoreButton} from "../../../../components/UniversalReadMoreButton/UniversalReadMoreButton";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";
import ScrollAnimation from "react-animate-on-scroll";


class AboutUs extends Component<AboutUsProps, any> {
    form: RefObject<HTMLFormElement>
    dropdown: RefObject<HTMLDivElement>

    constructor(props: any) {
        super(props);
        this.dropdown = React.createRef();
        this.state = {
            name: '',
            description: '',
        };
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    setSizeCLass = () => {
        if (this.props?.data.htmlSlice?.size === 'XS') {
            this.setState(() => ({sizeClass: 25}));
        } else if (this.props?.data.htmlSlice?.size === 'S') {
            this.setState(() => ({sizeClass: 50}));
        } else if (this.props?.data.htmlSlice?.size === 'M') {
            this.setState(() => ({sizeClass: 75}));
        } else if (this.props?.data.htmlSlice?.size === 'L') {
            this.setState(() => ({sizeClass: 100}));
        }
    }


    handleChange(event: any) {
        const target = event.target;
        const name = target.name;
        const value = this.extractEventValue(target);
        // @ts-ignore
        this.setState({
            [name]: value
        });
    }

    private extractEventValue(target: any) {
        if (target.type === 'checkbox') {
            return target.checked;
        } else if (target.type === 'file') {
            return target.files!![0];
        } else {
            return target.value;
        }
    }

    componentDidMount() {
        this.setSizeCLass();
    }

    render() {
        return (
            <section id={this.props.htmlId}
                     className={`${this.props.themeClass} ${this.props.shadeClass} testingFontFamily site_widget aboutUs-sec ptb_80`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 section-header">
                            <ScrollAnimation animateIn='fadeInLeft'>
                                <HeadingTitles heading={this.props.data['title']} classesObject={{
                                    headingClass: 'heading-style mb-0',
                                    descriptionClass: 'sub-title text-muted'
                                }} layout="layout_1" headingTag="h2" alignment="left"/>
                                <div className={'label-line'}></div>
                                <div dangerouslySetInnerHTML={{__html: this.props.data?.content?.html || ''}}
                                     className="title_description mt-3" style={{textAlign: "justify"}}></div>
                                <UniversalReadMoreButton payload={this.props?.data?.readMoreButton}
                                                         pathName={this.props?.location?.pathname ?? ""}
                                                         siteId={this.props?.siteId}/>
                            </ScrollAnimation>

                        </div>
                        <div className="col-lg-6 position-relative">
                            <ScrollAnimation animateIn='fadeInRight'>
                                <MediaSelector data={this.props?.data?.htmlSlice}/>
                            </ScrollAnimation>

                        </div>
                    </div>

                </div>
            </section>
        );
    }
}

export default AboutUs;
