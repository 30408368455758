import React, {Component} from "react";
import "./contact_info_bsc.scss";
import {ComponentProps} from "../../../../util/CommonDto";

interface ContactInfoProps extends ComponentProps<any, any> {
    data: any
}

class ContactInfoBsc extends Component<ContactInfoProps, any> {
    render() {
        return <div className="container">
        </div>
    }
}

export default ContactInfoBsc;
