import React, {Component} from "react";
import "./GalleryLayout3.css";
import {GalleryProps} from "../../../../models/GalleryModal";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from "@fortawesome/free-solid-svg-icons/faChevronRight";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

interface LightBoxState {
    showLightBox: boolean,
    imageSelected: string | undefined,
    imageIndex: number,
}

class GalleryLayout3 extends Component<GalleryProps, LightBoxState> {

    constructor(props: GalleryProps) {
        super(props);
        this.state = {
            imageSelected: '',
            showLightBox: false,
            imageIndex: 0,
        }
    }

    showLightBoxView = (event: any, image: any, index: number) => {
        this.setState({
            imageSelected: image,
            showLightBox: true
        })
    };

    hideLightBox = () => {
        this.setState({
            imageSelected: this.state.imageSelected,
            showLightBox: false,
        });
    }

    showNext = (e: any) => {
        e.stopPropagation();
        const images = this.props.data?.photos;
        const currentIndex = this.state.imageIndex;
        if (currentIndex >= images.length - 1) {
            const nextImage = images[0].url;
            this.setState({
                imageSelected: nextImage,
                showLightBox: this.state.showLightBox,
                imageIndex: 0
            });
        } else {
            const nextImage = images[currentIndex + 1].url;
            this.setState({
                imageSelected: nextImage,
                showLightBox: this.state.showLightBox,
                imageIndex: currentIndex + 1
            });
        }
    }

    showPrevious = (e: any) => {
        e.stopPropagation();
        const images = this.props.data?.photos;
        const currentIndex = this.state.imageIndex;
        if (currentIndex <= 0) {
            const prevImage = images[images.length - 1].url;
            this.setState({
                imageSelected: prevImage,
                showLightBox: this.state.showLightBox,
                imageIndex: images.length - 1
            });
        } else {
            let prevImage = images[currentIndex - 1].url;
            this.setState({
                imageSelected: prevImage,
                showLightBox: this.state.showLightBox,
                imageIndex: currentIndex - 1
            });
        }
    }

    // render() {
    //     return <section id={this.props.htmlId} style={this.state.showLightBox ? {height: "100%", overflowY: "hidden"} : {}}
    //                     className={`${this.props.themeClass} gallery-sec_l3 testingFontFamily pb-5 pt-5 d-flex flex-column justify-content-center`}>
    //         <div className="container">
    //             {this.props.data['displayHeader'] ?
    //                 <div className="row">
    //                     <div className="section-heading text-center mb-5">
    //                         <h2>{this.props.data['title']}</h2>
    //                         <span className="label-line"></span>
    //                         <p>{this.props.data['subtitle']}</p>
    //
    //                     </div>
    //                 </div> : ''
    //             }
    //
    //
    //             <div className="gallery">
    //                 {
    //                     this.props.data?.photos.map((photo: any, index: number) => (
    //                         <div className="pics" key={photo.cid}>
    //                             <div className="content-overlay1" style={{
    //                                 // height: `${this.props.data['height'] ? this.props.data['height']: 120}px`,
    //                                 padding: `${this.props.data['padding'] ? this.props.data['padding'] : 7}px`,
    //                                 objectFit: "cover",
    //                             }}></div>
    //                             <img className="card-img-top" src={photo.url} style={{
    //                                 width: "100%",
    //                                 // height: `${this.props.data['height'] ? this.props.data['height']: 120}px`,
    //                                 padding: `${this.props.data['padding'] ? this.props.data['padding'] : 7}px`,
    //                                 objectFit: "cover",
    //                             }}
    //                                  onClick={(e) => {
    //                                      if (this.props.data['showZoomBtn']) {
    //                                          this.showLightBoxView(e, photo.url, index)
    //                                      }
    //                                  }} loading="lazy"/>
    //                             <div className="content-details1">
    //                                 <h3 className="content-title1">{photo.title}</h3>
    //                                 {/*<p className="content-text1">This is a short description</p>*/}
    //                             </div>
    //                         </div>
    //                     ))
    //                 }
    //                 {
    //                     this.state.showLightBox ?
    //                         <div id="lightbox" onClick={this.hideLightBox}>
    //                             <div style={{
    //                                 position: "absolute",
    //                                 right: 0,
    //                                 top: 0,
    //                             }} className="mt-4">
    //                                 <FontAwesomeIcon type="button" icon={faTimes as IconProp} className="icons mx-5"
    //                                                  style={{color: "white", fontSize: "30px", userSelect: "none",}}
    //                                                  onClick={(e) => {
    //
    //                                                  }}/>
    //                             </div>
    //                             <FontAwesomeIcon type="button" icon={faChevronLeft as IconProp} className="icons mx-5"
    //                                              style={{
    //                                                  color: "white",
    //                                                  fontSize: "35px",
    //                                                  userSelect: "none",
    //                                                  background: '#000',
    //                                                  height: 60,
    //                                                  width: 60,
    //                                                  borderRadius: 50,
    //                                                  padding: 10
    //                                              }} onClick={(e) => {
    //                                 this.showPrevious(e)
    //                             }}/>
    //                             <img id="lightbox-img" src={this.state.imageSelected} loading="lazy"/>
    //                             <FontAwesomeIcon type="button" icon={faChevronRight as IconProp} className="icons mx-5"
    //                                              style={{
    //                                                  color: "white",
    //                                                  fontSize: "35px",
    //                                                  userSelect: "none",
    //                                                  background: '#000',
    //                                                  height: 60,
    //                                                  width: 60,
    //                                                  borderRadius: 50,
    //                                                  padding: 10
    //                                              }} onClick={(e) => {
    //                                 this.showNext(e)
    //                             }}/>
    //                         </div>
    //                         : ''
    //                 }
    //             </div>
    //         </div>
    //     </section>
    // }
}

export default GalleryLayout3;

