import {Component} from "react";
import "./Testimonial.scss";
import {TestimonialProps} from "../../../../models/TestimonialProps";
import LazyLoad from "react-lazyload";

class Testimonial extends Component<TestimonialProps, any> {

    render() {
        return <div className="item-inner">
            <div className="card">
                <div className="card-body">
                    <blockquote className="icon mb-0">
                        <p id="message" className="sub-title">{this.props?.['comment']}</p>
                        <div className="blockquote-details d-flex align-items-center">
                            {
                                this.props?.image.url !== '' ?
                                    <LazyLoad offset={100}>
                                    <img id="avatar" className="rounded-circle"
                                         src={this.props?.image.url} loading="lazy" alt={""}></img>
                                    </LazyLoad>
                                    :
                                    <div
                                        id="avatar-name">{this.props?.name.substring(0, 1).toUpperCase() + this.props.name.substring(this.props.name.indexOf(" ") + 1, (this.props.name.indexOf(" ") + 2)).toUpperCase()}</div>
                            }
                            <div className="name-box">
                                <p id="name" className="mini-title">{this.props?.['name']}</p>
                                <p id="designation" className="sub-title">{this.props?.['designation']}</p>
                            </div>
                        </div>
                    </blockquote>
                </div>
            </div>
        </div>;
    }
}

export default Testimonial;
