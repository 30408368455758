import React, {Component} from "react";
import {AppPortfolioProps} from "../../../../models/BannerModel";
import {Carousel} from "react-bootstrap";
import './AppPortfolio.scss'

class AppPortfolio extends Component<AppPortfolioProps, any> {

    slidesData = [];

    constructor(props: any) {
        super(props);

        this.setItems();
        this.state = {
            name: '',
            description: '',
        };
    }

    setItems = () => {
        let slides: any = [];
        this.props.data?.slides.map((slide: any) => {
            if (slide.active) {
                slides.push(slide);
            }
        });
        this.slidesData = slides;
    }

    render() {
        return <section id={this.props.htmlId}
                        className={`${this.props.themeClass} testingFontFamily portfolio_sec pt-0 pb-0`}>

            {/*<div className="row justify-content-center">*/}
            {/*    <div className="section-heading text-center mb-5">*/}
            {/*        <h2>{this.props.data['title']}</h2>*/}
            {/*        <span className="label-line"></span>*/}
            {/*        <p>{this.props.data['subtitle']}</p>*/}
            {/*        <p>{this.props.data['description']}</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="container">*/}
            <div className="wrapper">
                <div className="background_image">
                    <div className="bg" style={{
                        backgroundImage: `url(${this.props?.data?.bgImage?.url})`
                    }}>
                    </div>
                </div>

                <div className="amulator_wrapper">
                    <div className="amulator_img">
                        <Carousel indicators={false} controls={false}>
                            {this.slidesData?.map((slide: any, index: any) =>
                                <Carousel.Item key={index} interval={(this.slidesData?.length === 1) ? null : this.props.data?.slideDelay}>
                                    <div className="d-flex justify-content-center">
                                        <img className="location-img img-fluid animate fadeInleft three" src={slide['url']} loading="lazy"/>
                                    </div>
                                </Carousel.Item>
                            )}
                        </Carousel>
                    </div>
                </div>
                <div className="container-text position-absolute top-50"><h2>Asian Bank</h2><h3>A mobile app to make cryptocurrency transactions mainstream for
                    consumers</h3><p className="wrapLine">A leading digital platform that allows end-users to store, transact, and convert
                    cryptocurrencies in fiat currencies.</p>
                </div>
            </div>
            {/*</div>*/}
        </section>;
    }
}

export default AppPortfolio;
