import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface SocialIconProps {
    url: string;
    icon: any;
}

class SocialIcon extends React.Component<SocialIconProps, any> {
    render() {
        return (this.props.url?.trim().length > 0 &&
            <a className="footer-icon d-inline-block text-center margin-right-5 rounded-circle border-1" target={"_blank"} rel={"noreferrer"} href={this.props.url}>
                <FontAwesomeIcon icon={this.props.icon}/>
            </a>
        )
    }
}
export default SocialIcon;
