import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {JobProps} from "../../models/BannerModel";
import {Job} from "../../models/Job";
import {Helmet} from "react-helmet";
import axiosClient from "../../config/AxiosRestClient";
import {WidgetRenderer} from "../../themes/bsc/widgets/WidgetRenderer";
import {JobRepo} from "../../repo/JobRepo";
import ThemeManager from "../../themes/ThemeManager";

export const JobDetails = (props: JobProps) => {
    const params = useParams<{ jobId: string, id?: string, 0: string }>();
    const [details, setDetails] = useState<any | undefined>(props.staticContext?.data.siteDetails);
    const [jobDetail, setJobDetail] = useState<Job>(props.staticContext?.data.jobDetails);
    const [themeSlug, setThemeSlug] = useState<any>('');
    const [theme, setTheme] = useState("");

    useEffect(() => {
        fetchBrandDetails(params.id)
        getJobDetail(params.jobId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.jobId])

    const fetchBrandDetails = (siteId: string | undefined) => {
        axiosClient.get(`/listingfy-service/website/template`, {
            params: {'siteId': siteId, page: "job"}
        }).then((res) => {
            setEnqueueFontFamily(res?.data?.theme?.settings?.theme?.fontFamily);
            ThemeManager.setThemeSlug(res?.data?.theme?.slug ?? '');
            setThemeSlug(res?.data?.theme?.slug ?? '');
            switchTheme(ThemeManager.getCurrentThemeSlug());
            setDetails(res?.data)
        }).catch((err) => {
            console.log("#27 Job Details:", err);
        });
    }

    const setEnqueueFontFamily = (fontFamilySettings: any) => {
        for (let key in fontFamilySettings) {
            if (fontFamilySettings.hasOwnProperty(key)) {
                const link = document.createElement('link');
                const rootVariable = document.documentElement;
                link.rel = 'stylesheet';
                link.href = fontFamilySettings[key]?.url;
                document.head.appendChild(link);
                rootVariable.style.setProperty('--' + key + 'FontFamily', fontFamilySettings[key]?.name);
            }
        }
    }

    const switchTheme = (themeSlug: any) => {
        if (themeSlug) {
            switch (themeSlug) {
                case 'retro_theme':
                    require('../../themes/retro_theme/retro_theme.scss');
                    setTheme("retro_theme")
                    break;
                case 'ocean_theme':
                    require('../../themes/ocean_theme/ocean_theme.scss');
                    setTheme("ocean_theme")
                    break;
                case 'elegant_theme':
                    require('../../themes/elegant_theme/elegant_theme.scss');
                    setTheme("elegant_theme")
                    break;
                case 'autumn_theme':
                    require('../../themes/autumn_theme/autumn_theme.scss');
                    setTheme("autumn_theme")
                    break;
                case 'classic_theme':
                    require('../../themes/classic_theme/classic_theme.scss');
                    setTheme("classic_theme")
                    break;
                case 'environmental_theme':
                    require('../../themes/environmental_theme/environmental_theme.scss');
                    setTheme("environmental_theme")
                    break;
                default:
                    require('../../themes/bsc/bsc.scss');
            }
        } else {
            require('../../themes/bsc/bsc.scss');
        }
    }

    const getJobDetail = (jobId: string, siteId?: string) => {
        JobRepo.getJobDetails(jobId, siteId).then((res) => {
            setJobDetail(res.data);
        }).catch((err) => {
            console.log("#35 Job Details:", err);
        });
    }

    const getWidgetClasses = (shadeClass: string | undefined, widgetClass: string) => {
        console.log('widgetClass', widgetClass);
        if (widgetClass) {
            return ((shadeClass) ? widgetClass + ' __' + shadeClass : widgetClass).toLowerCase();
        } else {
            return '';
        }
    }

    return <div className={theme}>
        <Helmet>
            <title>{jobDetail?.title}</title>
            <meta name="description" content={jobDetail?.description}/>
            <meta property="og:title" content={jobDetail?.title}/>
            <meta property="og:description" content={jobDetail?.description}/>
            <meta property="og:type" content="product"/>
        </Helmet>
        {
            (details ? details['widgets'] : [])
                .filter((widget: any) => widget['active'] === true)
                .map((widget: any) => (
                    <div key={widget.id} id={widget['id']}
                         className={`${(themeSlug) ? 'widget_theme_section' : ''} section-wrapper position-relative`}>
                        {
                            WidgetRenderer.renderV2(params.id ?? '', {}, widget, props, props, jobDetail, getWidgetClasses(widget['config']?.['shade'], widget['config']?.['widgetClass']), widget['config']?.['themeShade']?.['shadeClass'])
                        }
                    </div>
                ))
        }
    </div>
}
