import {Component, FormEvent} from "react";
import {ContactUsProps} from "../../../../models/ContactUsModel";
import "./ContactUsLayout1.scss";
import React, {RefObject} from "react";
import axiosClient from "../../../../config/AxiosRestClient";
import { faHome as home, faPhone as phone} from "@fortawesome/free-solid-svg-icons";
import LoaderButton from "../../../../components/LoaderButton";
import ScrollAnimation from "react-animate-on-scroll";
import "bootstrap-icons/font/bootstrap-icons.css";


interface ContactUsBscState {
    usermessage?: UserMessage,
    processing: boolean,
    messageSent: boolean,
    name: string,
    email: string,
    mobile: string,
    subject: string,
    message: string,
}

interface UserMessage {
    message: string
    type: string
}

class ContactUsLayout1 extends Component<ContactUsProps, ContactUsBscState> {
    form: RefObject<HTMLFormElement>
    dropdown: RefObject<HTMLDivElement>

    constructor(props: ContactUsProps) {
        super(props);
        this.dropdown = React.createRef();
        this.state = {
            processing: false,
            messageSent: false,
            name: '',
            email: '',
            message: '',
            mobile: '',
            subject: '',
        }
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event: any) {
        const target = event.target;
        const name = target.name;
        const value = this.extractEventValue(target);
        // @ts-ignore
        this.setState({
            [name]: value
        });
    }

    private extractEventValue(target: any) {
        if (target.type === 'checkbox') {
            return target.checked;
        } else if (target.type === 'file') {
            return target.files!![0];
        } else {
            return target.value;
        }
    }


    getPayload = (): any => {
        const payload = {
            'siteId': this.props.siteId,
            'email': this.state.email,
            'name': this.state.name,
            'mobile': this.state.mobile,
            'subject': this.state.subject,
            'message': this.state.message,
        }
        return payload;
    }

    contactUser = () => {
        this.setState({processing: true});
        axiosClient.post(`/listingfy-service/website/` + this.props.siteId + '/contact-us', this.getPayload()).then((res) => {
            if (res.status === 200) {
                this.setState(() => ({usermessage: {message: "Message Sent Successfully", type: "success"}}));
                this.setState({processing: false, messageSent: true});
            } else {
                this.setState({usermessage: {message: "Unknown error sending message: " + res.status, type: "error"}});
                this.setState({processing: false, messageSent: false});
            }
        }).catch((error: any) => {
            console.log("#90 Contact Us Layout 1:", error);
            this.setState(() => ({usermessage: {message: "Error occurred", type: "error"}}))
            this.setState({processing: false, messageSent: false});
        });
    }


    submitContactForm = (event: FormEvent) => {
        event.preventDefault();
        this.contactUser();
    }

    render() {
        return <section id={this.props.htmlId}
                        className={`${this.props.themeClass} contactUs_l1 ptb_80 testingFontFamily`}>
            <div className="container">
                <div className="row justify-content-center mb-5">
                    <ScrollAnimation animateIn='fadeInUp'>
                        <div className="section-heading text-center">
                            <h2 className="heading-style">{this.props.data['title'] ?? "We'd love to hear from you!"}</h2>
                            <span className="label-line"></span>
                            <p className="d-none d-sm-block sub-title">{this.props.data['subtitle'] ? this.props.data['subtitle'] : "Fill out this short form and someone from our team will be in touch!"}</p>
                        </div>
                    </ScrollAnimation>
                </div>
                <ScrollAnimation animateIn='fadeInUp'>
                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <div className="contact-us">
                                <p className="mb-3">
                                    {this.props.data['description'] ? this.props.data['description'] : "If you have any feedback or suggestions share with us and we will get back to you in five bussiness days."}
                                </p>
                                <ul>
                                    {this.props.data['location'] ?
                                        <li className="py-3">
                                            <a className="media d-flex align-items-center">
                                                <div className="social-icon theme_icons me-3">
                                                    {/*<FontAwesomeIcon icon={home} className="lock-icon icons"/>*/}
                                                    <i className="bi bi-house"></i>
                                                </div>
                                                <span
                                                    className="media-body align-self-center">{this.props.data['location']}</span>
                                            </a>
                                        </li> : ''}

                                    {this.props.data['phone'] ?
                                        <li className="py-3">
                                            <a className="media d-flex align-items-center">
                                                <div className="social-icon theme_icons me-3">
                                                    <a href={`tel:${this.props.data['phone']}`}>
                                                        {/*<FontAwesomeIcon icon={phone} className="lock-icon icons"/>*/}
                                                        <i className="bi bi-telephone"></i>
                                                    </a>
                                                </div>
                                                <span
                                                    className="media-body align-self-center">{this.props.data['phone']}</span>
                                            </a>
                                        </li> : ''}

                                    {this.props.data['whatsapp'] ?
                                        <li className="py-3">
                                            <a className="media d-flex align-items-center">
                                                <div className="social-icon theme_icons me-3">
                                                    {/*<FontAwesomeIcon icon={faWhatsapp as IconProp} className="lock-icon icons"/>*/}
                                                    <i className="bi bi-whatsapp"></i>
                                                </div>
                                                <span
                                                    className="media-body align-self-center">{this.props.data['whatsapp']}</span>
                                            </a>
                                        </li> : ''}

                                    {this.props.data['email'] ?
                                        <li className="py-3">
                                            <a className="media d-flex align-items-center">
                                                <div className="social-icon theme_icons me-3">
                                                    <a href={`mailto:${this.props.data['email']}`}>
                                                        <i className="bi bi-envelope"></i>
                                                        {/*<FontAwesomeIcon icon={message} className="lock-icon icons "/>*/}
                                                    </a>
                                                </div>
                                                <span
                                                    className="media-body align-self-center">{this.props.data['email']}</span>
                                            </a>
                                        </li> : ''}

                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="contact-box text-center">
                                {this.state.messageSent ?
                                    <div id="contact-form-box" className={"alert alert-success"}>Query submitted
                                        successfully</div> :
                                    <form id="contact-form" onSubmit={this.submitContactForm} role="form">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                           name={"name"}
                                                           placeholder="Name"
                                                           onChange={event => this.handleChange(event)} required/>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control"
                                                                   name={"mobile"}
                                                                   placeholder="Mobile Number" maxLength={10}
                                                                   pattern={"[0-9]*"}
                                                                   onChange={event => this.handleChange(event)}
                                                                   required/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <input type="email" className="form-control"
                                                                   name={"email"}
                                                                   placeholder="Email"
                                                                   onChange={event => this.handleChange(event)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control"
                                                           name={"subject"}
                                                           placeholder="Subject"
                                                           onChange={event => this.handleChange(event)}/>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <textarea className="form-control"
                                                              name={"message"}
                                                              placeholder="Message"
                                                              onChange={event => this.handleChange(event)}
                                                              required/>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-12">
                                                <LoaderButton loading={this.state.processing}
                                                              label={this.props.data['action'] ? this.props.data['action'] : "Submit query"}
                                                              cssName={"btn theme_btn"}/>
                                                {this.state.usermessage?.type === 'error' ?
                                                    <div
                                                        className={"alert alert-danger"}>{this.state.usermessage?.message}</div> : null}
                                            </div>
                                        </div>
                                    </form>}
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </section>;
    }
}

export default ContactUsLayout1;
