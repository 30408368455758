import {Route, Switch} from "react-router";
import {TenantHome} from "./TenantHome";
import React, {Fragment, useEffect} from "react";
import {ProductDetails} from "../themes/bsc/widgets/DetailPages/ProductDetails";
import {JobDetails} from "./job/JobDetails";
import {BlogDetails} from "./blog/blogDetails";
import ReactGA from 'react-ga';
import {useAnalytics} from "../components/analytics/UseAnalytics";

const TenantIndex = (props: any) => {
    const {initialized} = useAnalytics();

    useEffect(() => {
        ReactGA.initialize('G-0DKT6XF0ED');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return <Fragment>
        <Switch>
            <Route path="/preview/:id/product/:productId" component={ProductDetails} />
            <Route path="/product/:productId" component={ProductDetails} />
            <Route path="/preview/:id/job/:jobId*" component={JobDetails}/>
            <Route exact path="/job/:jobId"  component={JobDetails} />
            <Route path="/preview/:id/blog/:blogId" component={BlogDetails}/>
            <Route exact path="/blog/:blogId"  component={BlogDetails} />
            <Route exact path="/preview/:id"  component={TenantHome} />
            <Route exact path="/preview/:id/*"  component={TenantHome} />
            <Route exact path="/*"  component={TenantHome} />
        </Switch>
    </Fragment>
}

export default TenantIndex;



