import {Nav, NavDropdown, NavLink} from "react-bootstrap";
import {PageRouteBuilder} from "../../../../../util/PageRouteBuilder";
import {MenuItemDto} from "./MenuItems";
import React, {Fragment, useState} from "react";
import history from "../../../../../util/History";
import {Link} from 'react-router-dom';
import {Link as Scroller} from 'react-scroll';

type MenuItemProps = {
    item: MenuItemDto
    activeMenu?: string,
    pathName: string,
    pageId: string,
    handleOffCanvas: () => void;
}
export const MenuItem = (props: MenuItemProps) => {
    const [show, setShow] = useState<boolean>(false);
    const showIcon = (label: string) => {
        return <Fragment> {label} <i className="bi bi-chevron-down"/></Fragment>;
    }

    const handleOffCanvas = () => {
        props.handleOffCanvas();
    }

    if (props.item?.active) {
        return <div key={props.item.id} className="menu-item" aria-expanded="true">
            {props.item.children?.length > 0 ?
                <NavDropdown title={showIcon(props.item?.label)} id={props.item.id} show={show}
                             className={props.item.children.findIndex((child: any) => child.page === props.activeMenu) !== -1 ? "active" : ""}
                             aria-expanded="true"
                             onMouseEnter={() => setShow(true)}
                             onMouseLeave={() => setShow(false)}>
                    {/* eslint-disable-next-line array-callback-return */}
                    {props.item.children?.map((child: any, index: number) => {
                            if (child?.active) {
                                return <NavDropdown.Item
                                    aria-expanded="true"
                                    key={index}
                                    className={`${props.activeMenu === (child.page + (child.section.toString() !== "" ? `#${child.section}` : '')) ? 'text-active' : ''}`}
                                    onClick={() => history.push(`${PageRouteBuilder.buildPageRoute(props.pathName, `${child.page}${child.section ? `#${child.section}` : ''}`, props.pageId)}`)}>
                                    {child.label}
                                </NavDropdown.Item>
                            }
                        }
                    )}
                </NavDropdown> :
                (props.item.section === "") ? <Link
                        to={`${PageRouteBuilder.buildPageRoute(props.pathName, `${props.item.page}${props.item.section ? `#${props.item.section}` : ''}`, props.pageId)}`}
                        className={`cursor-pointer nav-link ${props.activeMenu === (props.item.page + (props.item.section.toString() !== "" ? `#${props.item.section}` : '')) ? 'text-active' : ''}`}>
                        {props.item.label} </Link> :

                    <Scroller
                        activeClass="text-active"
                        to={props.item.section}
                        spy={true}
                        smooth={true}
                        delay={0}
                        offset={-70}
                        duration={300}
                        className={`nav-link cursor-pointer ${props.activeMenu === (props.item.page + (props.item.section.toString() !== "" ? `#${props.item.section}` : '')) ? 'text-active' : ''}`}
                        onClick={handleOffCanvas}>
                        {props.item.label}
                    </Scroller>
            }
        </div>
    } else {
        return null;
    }
}
