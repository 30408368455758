import axiosClient from "../config/AxiosRestClient";

export class fileUploadRepo {
    static uploadFile(file: File) {
        return new Promise<MediaUploadResponse>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", file!);
            axiosClient.post(`/listingfy-service/website/job-response/upload`, formData,{
                headers: {
                    origin: origin
                },
                params: {
                    'siteId' : "",
                }
            }).then(res => {
                const cid = res.data['id'];
                const url = res.data['url'];
                resolve(new MediaUploadResponse(cid, url))
            }).catch(err => {
                console.log("#20 File Upload Repo:", err);
                reject(err);
            })
        })
    }

}
export class MediaUploadResponse {
    cid: string
    url: string

    constructor(cid: string, url: string) {
        this.cid = cid;
        this.url = url;
    }
}
