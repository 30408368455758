import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import axiosClient from "../../config/AxiosRestClient";
import {Helmet} from "react-helmet";
import {WidgetRenderer} from "../../themes/bsc/widgets/WidgetRenderer";
import {BlogProps} from "../../models/BannerModel";
import {BlogsRepo} from "../../repo/BlogsRepo";
import {Blog} from "../../models/Blog";
import ThemeManager from "../../themes/ThemeManager";

export const BlogDetails = (props: BlogProps) => {
    const params = useParams<{ blogId: string, id?: string, 0: string }>();
    const [details, setDetails] = useState<any | undefined>(props.staticContext?.data.siteDetails);
    const [blogDetail, setBlogDetail] = useState<Blog>(props.staticContext?.data.blogDetails);
    const [themeSlug, setThemeSlug] = useState<any>('');
    const [theme, setTheme] = useState("");
    useEffect(() => {
        fetchBrandDetails(params.id)
        getBlogDetail(params.blogId, params.id ?? '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.blogId])

    const fetchBrandDetails = (siteId: string | undefined) => {
        axiosClient.get(`/listingfy-service/website/template`, {
            params: {'siteId': siteId, page: "blog"}
        }).then((res) => {
            setEnqueueFontFamily(res?.data?.theme?.settings?.theme?.fontFamily);
            ThemeManager.setThemeSlug(res?.data?.theme?.slug ?? '');
            setThemeSlug(res?.data?.theme?.slug ?? '');
            switchTheme(ThemeManager.getCurrentThemeSlug());
            setDetails(res?.data);
        }).catch((err) => {
            console.log("#27 Blog Details:",err);
        });
    }
    const setEnqueueFontFamily = (fontFamilySettings: any) => {
        for (let key in fontFamilySettings) {
            if (fontFamilySettings.hasOwnProperty(key)) {
                const link = document.createElement('link');
                const rootVariable = document.documentElement;
                link.rel = 'stylesheet';
                link.href = fontFamilySettings[key]?.url;
                document.head.appendChild(link);
                rootVariable.style.setProperty( '--' + key + 'FontFamily', fontFamilySettings[key]?.name);
            }
        }
    }
    const switchTheme = (themeSlug: any) => {
        if (themeSlug) {
            switch (themeSlug) {
                case 'retro_theme':
                    require('../../themes/retro_theme/retro_theme.scss');
                    setTheme("retro_theme")
                    break;
                case 'ocean_theme':
                    require('../../themes/ocean_theme/ocean_theme.scss');
                    setTheme("ocean_theme")
                    break;
                case 'elegant_theme':
                    require('../../themes/elegant_theme/elegant_theme.scss');
                    setTheme("elegant_theme")
                    break;
                case 'autumn_theme':
                    require('../../themes/autumn_theme/autumn_theme.scss');
                    setTheme("autumn_theme")
                    break;
                case 'classic_theme':
                    require('../../themes/classic_theme/classic_theme.scss');
                    setTheme("classic_theme")
                    break;
                case 'environmental_theme':
                    require('../../themes/environmental_theme/environmental_theme.scss');
                    setTheme("environmental_theme")
                    break;
                default:
                    require('../../themes/bsc/bsc.scss');
            }
        } else {
            require('../../themes/bsc/bsc.scss');
        }
    }
    const getBlogDetail = (BlogId: string, siteId: string) => {
        BlogsRepo.getBlogsDetails(BlogId, siteId).then((res) => {
            setBlogDetail(res?.data);
        }).catch((err) => {
            console.log("#35 Blog Details:",err);
        });
    }
    const getWidgetClasses = (shadeClass: string | undefined, widgetClass: string) => {
        console.log('widgetClass', widgetClass);
        if (widgetClass) {
            return ((shadeClass) ? widgetClass + ' __' + shadeClass : widgetClass).toLowerCase();
        } else {
            return '';
        }
    }
    return <div className={theme}>
        <Helmet link={[{
            rel: 'icon',
            type: 'image/png',
            href: details?.website.favicon
        }]}>
            <title>{details?.seoMeta?.ogTitle ?? 'Please Title not set!!!'}</title>
            <meta name="description" content={details?.seoMeta?.description ?? 'Description not set'}/>
            <meta property="og:title" content={blogDetail?.title ?? 'og:title not defined'}/>
            <meta property="og:description" content={blogDetail?.description ?? 'og description not defined'}/>
            <meta property="og:image" content={blogDetail?.image?.url ?? 'url not defined'}/>
            <meta property="og:type" content={'Blog'}/>
        </Helmet>
        {
            (details ? details['widgets'] : [])
                .filter((widget: any) => widget['active'] === true)
                .map((widget: any) => (
                    <div key={widget.id} id={widget['id']} className={`${(themeSlug) ? 'widget_theme_section' : ''} section-wrapper position-relative`}>
                        {
                            WidgetRenderer.renderV2(params.id ?? '', {}, widget, props, props, blogDetail, getWidgetClasses(widget['config']?.['shade'], widget['config']?.['widgetClass']), widget['config']?.['themeShade']?.['shadeClass'])
                        }
                    </div>
                ))
        }
    </div>
}
