import React from 'react';

interface LoaderButtonProps {
    label: string;
    loading: boolean;
    cssName: string;
}

class LoaderButton extends React.Component<LoaderButtonProps, any> {
    render() {
        return (<button type="submit" value="submit" disabled={this.props.loading} className={this.props.cssName}>
            {
                this.props.loading ?
                    (<div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>) :
                    this.props.label
            }
        </button>)
    }
}

export default LoaderButton;
