import "./ProductDetails.scss"
import React, {useEffect, useState} from "react";
import axiosClient from "../../../../config/AxiosRestClient";
import {useParams} from "react-router";
import {ProductsProps} from "../../../../models/BannerModel";
import {ProductRepo} from "../../../../repo/ProductRepo";
import {Helmet} from "react-helmet";
import LazyLoad from "react-lazyload";

export type ProductDetail = { name: string, description: {markup: string, html: string}, price: string, image: {cid: string, url: string} };

export const ProductDetails = (props: ProductsProps) => {
    const [details, setDetails] = useState<any | undefined>(props.staticContext?.data.siteDetails);
    const params = useParams<{ id: string, productId: string }>();
    const [productData, setData] = useState<ProductDetail>();

    useEffect(() => {
        fetchBrandDetails(params.id);
        getProductDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchBrandDetails = (siteId: string | undefined) => {
        axiosClient.get(`/listingfy-service/website/template`, {
            params: {'siteId': siteId, page: "products"}
        }).then((res) => {
            // resolve(res.data);
            setDetails(res?.data);
        }).catch((err) => {
            console.log("#30 Product Details:", err);
        });
    }

    const getProductDetails = () => {
        ProductRepo.getProductDetail(undefined,params.id, params.productId).then((res) => {
            setData(res.data);
        });
    }

    return <div className="container">
        <Helmet link={[{
            rel: 'icon',
            type: 'image/png',
            href: details?.website.favicon
        }]}>
            <title>{details?.seoMeta?.ogTitle ?? 'Please Title not set!!!'}</title>
            <meta name="description" content={details?.seoMeta?.description ?? 'Description not set'}/>
            <meta property="og:title" content={productData?.name ?? 'og:title not defined'}/>
            <meta property="og:description" content={productData?.description?.html?.substring(0,3) ?? 'og description not defined'}/>
            <meta property="og:image" content={productData?.image?.url ?? 'url not defined'}/>
            <meta property="og:type" content={'Product'}/>
        </Helmet>
        <div className="container-fluid">
            <div className="row m-4">
                <div className="col-md-6 ">
                    <LazyLoad offset={100}>
                    <img style={{width: "600px", height: "400px", objectFit: "cover"}} alt={""}
                         src={productData?.image.url ?? ""} loading='lazy'/>
                    </LazyLoad>
                </div>
                <div className="col-md-6">
                    <div className="_product-detail-content">
                        <p className="_p-name"> {productData?.name ??"" } </p>
                        <div className="_p-price-box">
                            <div className="p-list">
                                {/*<span> M.R.P. : <i className="fa fa-inr"></i> <del> 13,999  </del>   </span>*/}
                                <span className="price">₹ {productData?.price ??""}</span>
                            </div>
                            <div className="_p-features">
                                <span> Description About this product:- </span>
                                {productData?.description}
                            </div>
                            <form action="" method="post" accept-charset="utf-8">
                                <ul className="spe_ul"></ul>
                                <div className="_p-qty-and-cart">
                                    <div className="_p-add-cart">
                                        <button className="btn-theme btn buy-btn mb-4" tabIndex={0}>
                                            <i className="fa fa-shopping-cart"></i> Buy Now
                                        </button>
                                        <button className="btn-theme btn btn-success" tabIndex={0}>
                                            <i className="fa fa-shopping-cart"></i> Add to Cart
                                        </button>
                                        <input type="hidden" name="pid" value="18"/>
                                        <input type="hidden" name="price" value="850"/>
                                        <input type="hidden" name="url" value=""/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
