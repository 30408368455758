import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from "redux-thunk";

const rootReducer = combineReducers({

});

const store = createStore(rootReducer,(typeof window!== "undefined")?window.REDUX_STATE: '',applyMiddleware(thunk));
export default store;

declare global {
    interface Window {REDUX_STATE: any; }
}
