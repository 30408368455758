import React from "react";
import {useEffect, useState} from "react";
import "./LinearPercentileIndicator.scss"

export type LinearPercentProps = {
    title: string,
    duration: number,
    percent: number
}

export const LinearPercentileIndicator = (props: LinearPercentProps) => {
    const [loaderValue, setLoaderValue] = useState<number>(0);

    useEffect(() => {
        if (loaderValue < props.percent) {
            setTimeout(() => {
                setLoaderValue(loaderValue === (props.percent - 1) ? (props.percent) : (loaderValue + 1));
            }, props.duration * 1000 / props.percent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loaderValue]);

    return <div className="linear-box-outer px-4">
        <div className="pb-3">
        <div className="linear-box d-flex justify-content-between">
            <p style={{margin: "0px"}}>{props.title}</p>
            <p style={{margin: "0px"}}>{loaderValue} %</p>
        </div>
        <div className="linear-percent-line-bg">
            <div className="linear-percent-completed" style={{width: `${(loaderValue / 100) * 400}px`}}></div>
        </div>
        </div>
    </div>
}
