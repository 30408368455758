import {Token} from '../models/Models';
import axios from "axios";
import {Constants} from "./Constants";

export class AccountRepo {

    static refreshAccessToken(refreshToken: string) {
        const date =new FormData();
        date.set("refresh_token",refreshToken);
        date.set("grant_type","refresh_token");
        return new Promise<Token|any>((resolve, reject)=> {
            axios.post<Token>(`${Constants.API_BASE_URL}/listingfy-auth/oauth/token`,date,{
                headers:{'Authorization': 'Basic YWNtZTphY21lc2VjcmV0'}
            }).then((res) => {
                resolve(res.data);
            }).catch((reason)=> {
                console.log("#17 Account Repo:", reason);
                reject(reason);
            })
        })
    }
}
