import {Component, FormEvent} from "react";
import {ContactUsProps} from "../../../../models/ContactUsModel";
import "./ContactUsLayout1.scss";
import React from "react";
import axiosClient from "../../../../config/AxiosRestClient";

interface ContactUsBscState {
    show: boolean,
    usermessage?: UserMessage,
    processing: boolean,
    messageSent: boolean,
    name: string,
    email: string,
    mobile: string,
    subject: string,
    message: string,
}

interface UserMessage {
    message: string
    type: string
}

class ContactUsLayout4 extends Component<ContactUsProps, ContactUsBscState> {

    constructor(props: ContactUsProps) {
        super(props);
        this.state = {
            show: false,
            processing: false,
            messageSent: false,
            name: '',
            email: '',
            message: '',
            mobile: '',
            subject: '',
        }
    }

    handleModal(event: FormEvent) {
        event.preventDefault()
        this.setState({show: !this.state.show})
    }

    handleChange(event: any) {
        const target = event.target;
        const name = target.name;
        const value = this.extractEventValue(target);
        // @ts-ignore
        this.setState({
            [name]: value
        });
    }

    private extractEventValue(target: any) {
        if (target.type === 'checkbox') {
            return target.checked;
        } else if (target.type === 'file') {
            return target.files!![0];
        } else {
            return target.value;
        }
    }


    getPayload = (): any => {
        const payload = {
            'siteId': this.props.siteId,
            'email': this.state.email,
            'name': this.state.name,
            'mobile': this.state.mobile,
            'subject': this.state.subject,
            'message': this.state.message,
        }
        return payload;
    }

    contactUser = () => {
        this.setState({processing: true});
        axiosClient.post(`/listingfy-service/website/` + this.props.siteId + '/contact-us', this.getPayload()).then((res) => {
            if (res.status === 200) {
                this.setState(() => ({usermessage: {message: "Message Sent Successfully", type: "success"}}));
                this.setState({processing: false, messageSent: true});
            } else {
                this.setState({usermessage: {message: "Unknown error sending message: " + res.status, type: "error"}});
                this.setState({processing: false, messageSent: false});
            }
        }).catch((error: any) => {
            console.log("#89 Contact Us Layout 4:",error);
            this.setState(() => ({usermessage: {message: "Error occured", type: "error"}}))
            this.setState({processing: false, messageSent: false});
        });
    }

    submitContactForm = (event: FormEvent) => {
        event.preventDefault();
        this.contactUser();
    }

    render() {
        return <div style={{width: "100%", backgroundColor: "#4A576D", padding: "80px"}}>
            <div style={{
                width: "100%",
                backgroundColor: "#2D3142",
                padding: "100px 50px",
                textAlign: "center",
                borderRadius: "10px"
            }}>
                <h1 style={{paddingBottom: "20px", color: "white", fontWeight: "bolder"}}>Want to reach us ?</h1>
                <p style={{paddingBottom: "5px", color: "white", fontWeight: "initial"}}>Reach us easily by filling out
                    some details and we will respond you at the earliest</p>
                <button type="button" data-bs-toggle="modal" data-bs-target="#myModal" className="btn btn-dark" style={{
                    padding: "10px 30px",
                    color: "#2D3142",
                    backgroundColor: "#BFC0C0",
                    fontWeight: "normal"
                }}>Contact Now
                </button>
            </div>

        </div>
    }
}

export default ContactUsLayout4;
