import axiosClient from "../config/AxiosRestClient";
import {Job} from "../models/Job";

export class JobRepo {
    static getJobs(siteId?: string){
        return axiosClient.get(`/listingfy-service/website/jobs`, {
            params: {
                siteId: siteId
            }
        });
    }

    static getJobDetails = (jobId: string, siteId?: string, origin?: string) => {
        return axiosClient.get<Job>(`/listingfy-service/website/jobs/${jobId}`, {
            params: {
                siteId: siteId
            },
            headers: {
                origin: origin
            }
        })
    }
}