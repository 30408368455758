import React, {Component} from "react";
import "./ourServicesLayout2.scss";
import ScrollAnimation from "react-animate-on-scroll";
import {OurServicesProps} from "../../../../models/BannerModel";
import "material-icons/iconfont/filled.css";


class OurServicesLayout2 extends Component<OurServicesProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }


    getNumberOfDivision = () => {
        let numberOfColumn: number = this.props?.data?.columns;
        if (numberOfColumn === 4) {
            return 3;
        } else if (numberOfColumn === 3) {
            return 4;
        } else {
            return 2;
        }
    }

    renderIconType = (type: string, icon: string) => {
        switch (type) {
            case "Material":
                return "material-icons";
            case "Bootstrap":
                return `bi bi-${icon}`;
            default:
                return "";
        }
    }

    render() {
        return (
            <section id={this.props.htmlId}
                     className={`${this.props.themeClass} testingFontFamily ourServices_l2 ptb_50 mt-5`}>
                <div className="container">
                    <ScrollAnimation animateIn='fadeInUp'>
                        <div className="row justify-content-center">
                            {this.props.data.services?.map((service: any, index: number) =>
                                <div key={service.id}
                                     className={`col-md-${this.getNumberOfDivision()} col-sm-12 mb-sm-4`}>
                                    <div className="serviceTitle text-center">
                                        {/*<span>*/}
                                        {/*    0{index + 1}*/}
                                        {/*</span>*/}
                                        <div className="services-wrapper d-flex mb-3 align-items-center">
                                            <div className="service_icon">

                                                {service?.icon?.type ==='Image' ?
                                                    <img src={service.icon?.image?.url ?? ''} style={{height:'50px' , objectFit:'cover'}}/> :
                                                    <span
                                                        className={this.renderIconType(service.icon?.type ?? "", service.icon?.className ?? "")}>{service.icon?.type === "Bootstrap" ? null : service.icon?.className}</span>}
                                            </div>
                                            <div className="sub-title">
                                                <h2>{service.title.toUpperCase()}</h2>
                                            </div>
                                        </div>
                                        <p className="serviceDescription">{service.description}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </ScrollAnimation>
                </div>
            </section>
        );
    }
}

export default OurServicesLayout2;
