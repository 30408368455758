import React, {useEffect, useState} from "react";
import {JobProps} from "../../../../models/BannerModel";
import {Link} from "react-router-dom";
import {useLocation, useParams} from "react-router";
import {Job} from "../../../../models/Job";
import {JobRepo} from "../../../../repo/JobRepo";
import {PageRouteBuilder} from "../../../../util/PageRouteBuilder";
import "./jobsLayout.scss";
import {
    faBriefcase as yrs,
    faRupeeSign as rupee,
    faMapPin as loc,
    faUser as jobtype,
    faPenNib as task,
    faClipboardList as desc
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HeadingTitles from "../common/sectionHeading/HeadingTitles";

export const JobsLayout1 = (props: JobProps) => {
    const params = useParams<any>();
    const [jobs, setJobs] = useState<Job[]>();
    const location = useLocation();

    useEffect(() => {
        getJobs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getJobs = () => {
        JobRepo.getJobs(params.id).then((res) => {
            setJobs(res.data);
        }).catch((e) => {
            console.log("#33 Jobs Layout:", e);
        })
    }

    return (<section id={props.htmlId} className="site_widget job_sec ptb_80 position-relative">

            <div className="container">
                <div className="row justify-content-center cursor-pointer">
                    <div className="section-heading text-center">
                        <HeadingTitles heading={props.data['title']} classesObject={{headingClass:'heading-style mb-0', descriptionClass: 'sub-title text-muted'}} layout="layout_1" headingTag="h2" description={props.data['subtitle']} alignment="left"/>

                    </div>
                </div>
                <div className="row">
                    <div className="d-grid mx-auto jobDetails__grid mt-4">
                        {
                            jobs?.map((job, index) => {

                                return <Link to={PageRouteBuilder.buildPageRoute(location.pathname, `job/${job.slug}`, params.id)}
                                             className="card border-0 shadow">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h6 className="text-black heading-text fs-5 fw-bold heading-style">
                                                {job.title }
                                            </h6>

                                            <div className="apply-btn heading-style">
                                                <button className="btn rounded-pill text-white fs-7">Apply Now</button>
                                            </div>
                                        </div>
                                        {job.location ?
                                        <div><span className="job-loc dark-blue sub-title"><FontAwesomeIcon icon={loc}
                                                                                                       className="lock-icon icons me-2 dark-blue"/>
                                            {job.location}
                                            </span>
                                        </div>: ''}

                                        <div className="mt-3 dark-blue info-list mb-2 mini-title">
                                            <ul className="d-flex">
                                                {job.experience ?
                                                    <li><span className="dark-blue"><FontAwesomeIcon icon={yrs}
                                                                                                     className="lock-icon icons me-2"/>{job.experience?.min} - {job.experience?.max} Yrs</span>
                                                    </li>
                                                    :''}

                                                {job.salary ?
                                                    <li><span className="mx-2 dark-blue"><FontAwesomeIcon icon={rupee}
                                                                                                          className="lock-icon icons me-2"/>{job.salary?.summary}</span>
                                                    </li> :''}

                                                {job.jobType ?
                                                    <li><span><FontAwesomeIcon icon={jobtype}
                                                                               className="lock-icon icons me-2 dark-blue"/>
                                                        {job.jobType}
                                                    </span></li> : ''}
                                            </ul>

                                            {job?.skill ?
                                                <div className="Key_Skills mb-2 position-relative">
                                                    <FontAwesomeIcon icon={task} className="lock-icon icons me-2 dark-blue"/>
                                                    <span className="me-1">{job?.skill}</span>
                                                </div> : ''}

                                            {job?.description ?
                                                <div>
                                                    <p className="card-text text-description jobDescription mb-sm-2 dark-blue"><FontAwesomeIcon
                                                        icon={desc} className="lock-icon icons me-2"/>{job?.description}</p>
                                                </div> : ''}
                                        </div>
                                    </div>
                                </Link>
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}




