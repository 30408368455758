import {Constants} from "./Constants";

export class SessionManager {
    static createSession = (accessToken: string , refreshToken?: string, isLoggedIn?: boolean , firstName?:string , lastName?:string , email?:string ,mobileNumber?:string ) =>{
        if(refreshToken){
            localStorage.setItem(Constants.REFRESH_TOKEN,refreshToken)
        }
        localStorage.setItem(Constants.ACCESS_TOKEN,accessToken)
        localStorage.setItem(Constants.FIRST_NAME ,firstName ?? '')
        localStorage.setItem(Constants.LAST_NAME,lastName ?? '')
        localStorage.setItem(Constants.EMAIL,email ?? '')
        localStorage.setItem(Constants.MOBILE_NUMBER,mobileNumber ?? '')
        localStorage.setItem(Constants.IS_LOGGED_IN,isLoggedIn === true?"true": "false")
    }

    static saveUserId = (userId: string) => {
        localStorage.setItem(Constants.USER_ID, userId ?? '');
    }
    static updateUserInfo = (firstName:string,lastName:string) => {
        localStorage.setItem(Constants.FIRST_NAME, firstName??'');
        localStorage.setItem(Constants.LAST_NAME, lastName??'');
    }

    static getUserId = () => {
        return localStorage.getItem(Constants.USER_ID ?? '');
    }

    static isLoggedIn =(): boolean =>{
        return localStorage.getItem(Constants.IS_LOGGED_IN) === "true";
    }
    static hasToken =(): boolean =>{
        return localStorage.getItem(Constants.ACCESS_TOKEN)!= null;
    }

    static updateSession = (token: string, refreshToken?: string)=>{
        if(refreshToken){
            localStorage.setItem(Constants.REFRESH_TOKEN, refreshToken);
        }
        this.setLoggedIn(true);
        localStorage.setItem(Constants.ACCESS_TOKEN,token);
    }
    static clearSession =() => {
        localStorage.removeItem(Constants.REFRESH_TOKEN)
        localStorage.removeItem(Constants.ACCESS_TOKEN)
        localStorage.setItem(Constants.IS_LOGGED_IN,"false")
    }
    static getRefreshToken =(): string | null => {
        if (typeof window !== "undefined")
            return localStorage.getItem(Constants.REFRESH_TOKEN)
        return null;
    }
    static getAccessToken =(): string | null => {
        if (!localStorage || typeof localStorage === "undefined")
            return null;
        return localStorage.getItem(Constants.ACCESS_TOKEN)
    }
    static setLoggedIn = (isLoggedIn: boolean) =>  {
        localStorage.setItem(Constants.IS_LOGGED_IN, isLoggedIn?"true":"false");
    }
}
