import React, {Component} from "react";
import {Carousel} from "react-bootstrap";
import {BannerProps} from "../../../../models/BannerModel";
import './BannerLayout2.scss';
import LazyLoad from "react-lazyload";

class BannerLayout2 extends Component<BannerProps, any> {
    slidesData = [];

    constructor(props: any) {
        super(props);

        this.setItems();
        this.state = {
            name: '',
            description: '',
        };

    }

    setItems = () => {
        let slides: any = [];
        this.props.data?.slides.map((slide: any) => {
            if (slide.active) {
                slides.push(slide);
            }
        });
        this.slidesData = slides;
    }

    render() {
        return <section id={this.props.htmlId} className={`${this.props.themeClass} banner-sec_l2 ptb_80 hero-2 testingFontFamily`}>
            <div className="single-slide">
                <div className="container">
                    <Carousel indicators={(this.slidesData?.length === 1) ? false : true} controls={false}>
                        {this.slidesData?.map((slide: any, index) =>
                            <Carousel.Item key={index} interval={(this.slidesData?.length === 1) ? null : this.props.data?.slideDelay}>
                                <div className="row">

                                    <div className="col-md-7">
                                        <div className="content-wrapper">
                                            <h1 className="animate fadeInDown one heading-style">{slide['title']}</h1>
                                            <p className="animate fadeInUp one sub-title">{slide['description']}</p>
                                            <button className="btn theme-btn effect-btn animate fadeInUp three mini-title">Explore
                                                more
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="image-wrapper d-flex justify-content-center">
                                            <img className="location-img img-fluid" src={slide['url']} loading='lazy' alt={""}/>
                                        </div>
                                    </div>
                                </div>
                            </Carousel.Item>
                        )}
                    </Carousel>
                </div>
            </div>

        </section>;
    }
}

export default BannerLayout2;
